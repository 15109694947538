import dva, { DvaInstance, Model } from 'dva';
import { createBrowserHistory } from 'history';

import { HttpRequest } from '@reco-m/core';

type dvaInstance = DvaInstance & { replaceModel(model: Model): void };

const base: any = document.querySelector('base[href]');

HttpRequest.defaultHeaders['terminal-type'] = 'website';

export const app: dvaInstance = dva({
    history: createBrowserHistory({ basename: base && base.getAttribute('href'), ...(client && (client as any).browserHistoryOptions) }),
    onError: (e) => console.warn('onError', e),
}) as any;
