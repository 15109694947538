import "./polyfills";

import { app } from "@reco-w/core-ui";
import { registerPageLayoutBlocks } from "@reco-w/layout-blocks-bitech";
// import { registerPageLayoutMyMenu } from "@reco-w/my-menu";
import { registerPageLayoutMyMenu } from "@reco-w/my-menu-bitech";
import Routers from "./router";
import reportWebVitals from "./reportWebVitals";
// import { MymenuBitech } from "@reco-w/my-menu-bitech";
app.router((props) => <Routers {...props} />);

app.start("#root");

registerPageLayoutMyMenu();
registerPageLayoutBlocks();
// registerPageLayoutMyMenu({ pageMyMenu: MymenuBitech.Page });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
