import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { IS_GET_ORDER_ON_HEADER } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { policyService } from "@reco-w/policy-service";

import { Namespaces } from "./common";

export namespace mymenuModel {
    export const namespace = Namespaces.myMenu;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "initState" });
                yield put({ type: "getCertify", message, isgetOrder: IS_GET_ORDER_ON_HEADER });
                yield put({ type: "getNotification", message });
                yield put({ type: "getPolicyDeclareMode", message });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getCertify({ message, isgetOrder, isRefreshUser }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser, message });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user,
                    member = memberCommonState.member;
                
                yield put({ type: "input", data: { member: member?.id ? member : null, user } });

                if (isgetOrder) {
                    yield put({ type: "getInstitution", companyId: member && member.companyId, message });
                    yield put({ type: "getCheckInDetail", userId: user?.currentUser?.id, message });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        *getCheckInDetail({ data, message, userId }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getCheckInDetail`, data, userId, message });
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]),
                    checkInData = workorderCommonState.checkInData;

                const checkOrderId = checkInData?.isCheckCancel ? checkInData?.checkOrderId : null,
                    checkStateId = checkInData?.isCheckCancel ? checkInData?.checkStateId : null;

                yield put({ type: "input", data: { checkInData, checkOrderId, checkStateId } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        *getInstitution({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getMemberConfig` });
                const configCommonState: any = yield select((state) => state[commonNamespaces.configCommon]);
                const isOnlyAllowOneMember = configCommonState.isOnlyAllowOneMember;

                yield yield put({ type: `${commonNamespaces.workorderCommon}/getInstitution` });
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]);
                const institution = workorderCommonState.institution;

                yield put({ type: "input", data: { institution: institution, isOnlyAllowOneMember } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取消息
         * @param { message }
         * @param { call, put, select }
         */
        *getNotification({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.noticeCommon}/getUnreadNotification`, message });
                const noticeCommonState = yield select((state) => state[commonNamespaces.noticeCommon]),
                    noticeCount = noticeCommonState!.noticeCount;

                yield put({ type: "input", data: { noticeCount } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getPolicyDeclareMode({ message }, { call, put }) {
            try {
                const config = yield call(policyService.getConfig);
                const unitConfig = config.unitConfig;
                const declareMode = unitConfig?.declareMode;
                yield put({ type: "input", data: { declareMode: declareMode } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}

export function registerMyMenuModel(model?: any) {
    app.model(model || mymenuModel);
}
