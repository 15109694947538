import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId, getCurrentParkName } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { policyDeclareService } from "@reco-w/policy-service";

import { Namespaces } from "./common";

export namespace newPolicyDeclareFormSimpleModel {
    export const namespace = Namespaces.newpolicyDeclareFormSimple;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, implementationId, declareId, declareCallback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getUserInfo", message });
                yield put({ type: "getImplementationDetail", message, implementationId });
                if (declareId) {
                    yield put({ type: "getDeclare", id: declareId, callback: declareCallback });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取用户信息
         * @param { message, data, callback }
         * @param { call, put,select }
         */
        *getUserInfo({ message }, { call, put, select }) {
            try {
                let formState = yield select((state) => state[Namespaces.newpolicyDeclareForm]);

                if (!formState.user) {
                    yield yield put({ type: `${Namespaces.newpolicyDeclareForm}/getUserInfo`, message });
                    formState = yield select((state) => state[Namespaces.newpolicyDeclareForm]);
                }

                yield put({
                    type: "input",
                    data: {
                        realName: formState?.realName || "",
                        inputerMobile: formState?.inputerMobile || "",
                        customerName: formState?.customerName,
                        customerId: formState?.customerId,
                        creditCode: formState?.creditCode,
                    },
                });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取实施细则详情
         * @param { message }
         * @param { call, put, select }
         */
        *getImplementationDetail({ message, id }, { call, put, select }) {
            try {
                let formState = yield select((state) => state[Namespaces.newpolicyDeclareForm]),
                    implementationDetail = formState?.implementationDetail;

                if (!formState.user) {
                    formState = yield select((state) => state[Namespaces.newpolicyDeclareForm]);
                }

                if (!implementationDetail) {
                    yield yield put({ type: `${Namespaces.newpolicyDeclareForm}/getImplementationDetail`, message, id });
                    formState = yield select((state) => state[Namespaces.newpolicyDeclareForm]);
                    implementationDetail = formState?.implementationDetail;
                }

                const declareAttach = JSON.parse(JSON.stringify(implementationDetail.declareAttach || []));
                declareAttach?.forEach((item, i) => {
                    item.key = i + 1;
                });

                yield put({ type: "input", data: { implementationDetail: implementationDetail, declareAttach } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取申报详情
         * @param { message, id, callback }
         * @param { call, put }
         */
        *getDeclare({ message, id, callback }, { call, put }) {
            try {
                const declare = yield call(policyDeclareService.get, id);
                yield put({ type: "input", data: declare });
                if (callback) {
                    yield call(callback!, declare);
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 提交申报
         * @param { callback, message, id, state }
         * @param { call, put, select }
         */
        *submit({ callback, message, id, state, extraParams }, { call, put }) {
            try {
                const data = {
                    customerName: state?.customerName,
                    creditCode: state?.creditCode,
                    realName: state?.realName,
                    inputerMobile: state?.inputerMobile,
                    customerId: state?.customerId,
                    status: 0,
                    policyImplementationDetailId: state?.implementationDetail?.id,
                    policyId: state?.implementationDetail?.policyId,
                    policyImplementationDetailTitle: state?.implementationDetail?.implementationDetailTitle,
                    parkId: getCurrentParkId(),
                    parkName: getCurrentParkName(),
                    ...extraParams,
                };

                const result = id ? yield call(policyDeclareService.put, id, data) : yield call(policyDeclareService.post, data);

                if (callback) {
                    yield callback(id || result);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSubmit: false } });
            }
        },
    };
}
app.model(newPolicyDeclareFormSimpleModel);
