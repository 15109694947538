import ReactDOM from "react-dom";
import React from "react";

import { PureComponent, getObjectProp } from "@reco-m/core";

export namespace ImageVertify {
    export interface IProps extends PureComponent.IProps {
        /**
         * 是否展示
         */
        isShow?: boolean;
        /**
         * 标题，默认为“身份验证”
         */
        title?: string;
        /**
         * 成功后回调
         */
        onSuccess?: () => void;
        /**
         * 关闭后回调
         */
        onClose?: () => void;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends ImageVertify.IProps = ImageVertify.IProps, S extends ImageVertify.IState = ImageVertify.IState> extends PureComponent.Base<P, S> { 
        static displayName = "ImageVertify"; 
        componentReceiveProps(props: P) {
            if (props.isShow !== this.props.isShow && props.isShow) {
                $.getScript("assets/js/image.vertify.js", () => {
                    const div = document.getElementById("imageVertifyId");
                    if (!div) {
                        const imageVertifyDiv = document.createElement("div");
                        imageVertifyDiv.setAttribute("id", "imageVertifyId");
                        document.body.appendChild(imageVertifyDiv);
                        ReactDOM.unstable_renderSubtreeIntoContainer(this, this.renderView(), imageVertifyDiv);
                    }

                    $(".vertify-box").css("display", "block");
                    $(".vertify-background").css("display", "block");
                    // 点击x
                    $(".vertify-cuo").click(() => {
                        $(".vertify-box").css("display", "none");
                        $(".vertify-background").css("display", "none");
                        props.onClose && props.onClose();
                    });
                    this.initRotateVerify();
                });
            }
        }

        /**
         * 初始化图片验证
         */
        initRotateVerify() {
            const slideImage = getObjectProp(client, "imageVertify.slideImage", ["assets/images/1.png", "assets/images/2.png", "assets/images/4.png", "assets/images/5.png"]);

            new RotateVerify("#rotateWrap1", {
                initText: "滑动将图片转正", //默认
                slideImage: slideImage, //arr  [imgsrc1,imgsrc2] 或者str 'imgsrc1'
                slideAreaNum: 20, // 误差范围角度 +- 10(默认)
                getSuccessState: () => {
                    //验证通过 返回  true;
                    setTimeout(() => {
                        $(".vertify-box").css("display", "none");
                        $(".vertify-background").css("display", "none");
                        this.props.onSuccess && this.props.onSuccess();
                    }, 1000);
                },
            });
        }

        renderView() {
            return (
                <div className="vertify-box">
                    <img src="assets/images/cw.png" className="vertify-cuo"></img>
                    <span className="vertify-top-s">{this.props.title || "身份验证"}</span>
                    <span className="vertify-top-x">拖动滑块，使图片角度为正</span>
                    <div id="rotateWrap1">
                        <div className="rotateverify-contaniner">
                            <div className="rotate-can-wrap">
                                <canvas className="rotateCan rotate-can" width="200" height="200"></canvas>
                                <div className="statusBg status-bg"></div>
                            </div>
                            <div className="control-wrap slideDragWrap">
                                <div className="control-tips">
                                    <p className="c-tips-txt cTipsTxt">滑动将图片转正</p>
                                </div>
                                <div className="control-bor-wrap controlBorWrap"></div>
                                <div className="control-btn slideDragBtn">
                                    <i className="control-btn-ico"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        render() {
            return null;
        }
    }
}
