import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getCurrentParkId, getCurrentParkName } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberCompanyService } from "@reco-w/member-service";

import { Namespaces, operateSourceEnum } from "./common";
export namespace certifyCompanyModel {
    export const namespace = Namespaces.certifyCompany;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, inputData, callback, certifyId }, { call, put }) {
            yield put({ type: "showLoading" });
            try {
                yield put({ type: "input", data: { ...inputData } });
                yield put({ type: "getMember", message, callback, certifyId });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                // yield put({ type: "hideLoading" });
            }
        },

        /**
         * 校验统一社会信用代码
         * @param { message, creditCode, callback }
         * @param { call }
         */
        *validateCreditcode({ message, creditCode, callback }, { call }) {
            try {
                const result = yield call(memberCompanyService.validateCreditcode, { creditCode });

                if (callback) {
                    yield call(callback, result);
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        *getMember({ message, callback, certifyId }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getMemberConfig`, message });
                const configCommonState: any = yield select((state) => state[commonNamespaces.configCommon]),
                    memberConfig = configCommonState!.memberConfig;

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                const mymenuState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    memberList = mymenuState!.memberList,
                    user = mymenuState.user;
                let member = mymenuState.member;

                if ((memberList?.length || 0) >= (memberConfig?.parkEnterpriseCertifiedMaxNum || 0) && member?.status === CertifyStatusEnum.allow) {
                    // 当已认证企业数大于等于最大认证数时
                    yield put({ type: "input", data: { submitSuccess: true } });
                }

                if (!certifyId) {
                    member = null;
                }

                yield put({
                    type: "input",
                    data: {
                        Account: user && user.currentUser,
                        realname: (user && user.currentUser && user.currentUser.realName) || "",
                        mobile: (user && user.currentUser && user.currentUser.mobile) || "",
                        currentUser: user && user.currentUser,
                        member,
                        companyId: member?.companyId,
                        company: member?.companyName,
                        creditCode: member?.creditCode,
                        companyaddress: member?.address,
                        userId: user && user.currentUser && user.currentUser.id,
                    },
                });

                if (callback) yield call(callback, member);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 提交认证
         */
        *certify({ message, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, message });
                const state: any = yield select((state) => state[Namespaces.certifyCompany]);
                const companyId = state!.companyId;
                let params2 = {
                    accountId: state!.userId,
                    address: state!.companyaddress,
                    name: state!.company,
                    parkId: getCurrentParkId(),
                    parkName: getCurrentParkName(),
                    realName: state!.realname,
                    mobile: state!.mobile,
                    creditCode: state!.creditCode,
                    operateSource: operateSourceEnum.web,
                };

                let certify;
                if (companyId) {
                    certify = yield call(memberCompanyService.put, companyId, params2);
                } else {
                    certify = yield call(memberCompanyService.post, params2);
                }
                yield call(callback, certify);
            } catch (e) {
                yield call(message!.error,  e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 赋值附件
         * @param { message, data }
         * @param { call, put }
         */
        *generateAttach({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                yield call(memberCompanyService.generateAttach, [data]);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(certifyCompanyModel);
