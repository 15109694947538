import React from "react";
import { Image } from "antd";
import { Row, Col, Carousel } from "antd";

import { PureComponent, transformAssetsUrl } from "@reco-m/core";

import { ImageAuto } from "./images.auto";

export namespace ImageFullScreen {
    export interface IProps extends PureComponent.IProps {
        /** 图片地址列表 */
        imgUrlList: any[];
        /** 裁剪的图片宽度 */
        cutWidth?: number;
        /** 裁剪的图片高度 */
        cutHeight?: number;
        /** 图片高度 */
        height?: string;
        /** 图片宽度 */
        width?: string;
        /** 栅格间隔，用于平铺时，默认为15 */
        rowgutter?: number;
        /** 栅格占位格数，用于平铺时，默认为4 */
        colspan?: number;
        /** 是否可点击， 默认为是 */
        canClick?: boolean;
        /** 是否是走马灯展示 */
        isCarousel?: boolean;
        /** 走马灯展示时的样式 */
        carouselClass?: string;
    }

    export interface IState extends PureComponent.IState {
        open?: boolean;
        index?: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static displayName = "ImageFullScreen";
        static defaultProps: any = {
            compress: false,
            rowgutter: 15,
            colspan: 4,
            canClick: true,
        };

        swiper: any;
        timeout: any;
        index: number;

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return { open: false } as S;
        }

        handleImageErrored() {
            this.setState({ open: false });
        }

        componentWillUnmount() {
            clearTimeout(this.timeout);
        }

        /** 图片点击 */
        onImageClick(i) {
            this.setState({ open: true, index: i });
        }

        /** 图片取消 */
        onCancel() {
            this.setState({ open: false });
        }

        /** 图片显示模态框 */
        renderModalImage(): React.ReactNode {
            const { imgUrlList } = this.props;
            return (
                <div className="hide">
                    <Image.PreviewGroup
                        preview={{
                            current: this.state.index,
                            visible: this.state.open,
                            onVisibleChange: (value) => {
                                this.setState({
                                    open: value,
                                });
                            },
                        }}
                    >
                        {imgUrlList &&
                            imgUrlList.length > 0 &&
                            imgUrlList.map((e, i) => {
                                return <Image key={i} src={transformAssetsUrl(e)} />;
                            })}
                    </Image.PreviewGroup>
                </div>
            );
        }

        /** 渲染走马灯展示 */
        renderCarousel(): React.ReactNode {
            const { width, height, imgUrlList, carouselClass, cutHeight, cutWidth } = this.props;
            return (
                <Carousel className={carouselClass || ""} dots={true} dotPosition={"bottom"} autoplay>
                    {imgUrlList && imgUrlList.length > 0 ? (
                        imgUrlList.map((img: any, i: any) => (
                            <ImageAuto.Component key={i} height={height} width={width} cutHeight={cutHeight} cutWidth={cutWidth} src={img} />
                        ))
                    ) : (
                        <ImageAuto.Component height={height} width={width} src={"assets/images/nopic.png"}/>
                    )}
                </Carousel>
            );
        }

        /** 渲染栅格平铺 */
        renderGrid(): React.ReactNode {
            const { imgUrlList, colspan, canClick, cutHeight, cutWidth } = this.props;
            return (
                <Row gutter={[16, 16]}>
                    {imgUrlList &&
                        imgUrlList.length > 0 &&
                        imgUrlList.map((img, i) => (
                            <Col onClick={() => (canClick ? this.onImageClick(i) : null)} span={colspan} key={i}>
                                <ImageAuto.Component width={"100%"} cutHeight={cutHeight} cutWidth={cutWidth} src={img} />
                            </Col>
                        ))}
                </Row>
            );
        }

        render(): React.ReactNode {
            const { isCarousel } = this.props;

            return (
                <>
                    {isCarousel ? this.renderCarousel() : this.renderGrid()}
                    {this.renderModalImage()}
                </>
            );
        }
    }
}
