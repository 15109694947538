import React from "react";
import { List } from "antd";

import { ViewComponent, NoData } from "@reco-w/core-ui";

import { IPageMapObj, renderPagination } from "./pagination.common";

export namespace SearchBase {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {
        searchKey: any;
    }

    export interface IState extends ViewComponent.IState {}
    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> { 
        static displayName = "SearchBase"; 
        /**
         * 作为判断是否需要重新搜索
         */
        pageIndex;
        /**
         * 作为判断是否需要重新搜索
         */
        pageSize;
        /**
         * 搜索类型
         */
        searchType;
        /**
         * 默认搜索数
         */
        defaultPageSize;
        /**
         * 获取列表的方法
         */
        listMap;

        componentMount() {
            window["scrollTop"]();;
        }

        componentDidMount() {
            this.getListByUrl(this.props);
        }

        componentReceiveProps(nextProps: Readonly<P>): void {
            let locationChanged = nextProps.location?.search !== this.props.location?.search || nextProps.location?.pathname !== this.props.location?.pathname;
            if (locationChanged) {
                this.getListByUrl(nextProps);
            }
        }

        componentWillUnmount() {
            this.dispatch({ type: "initState" });
        }

        /**
         * 处理Url，获取列表
         * @param props
         */
        getListByUrl(props: P, extraData?, searchTypeArr?) {
            this.dispatch({
                type: "getSearchList",
                callback: (pi, ps) => {
                    this.pageIndex = pi;
                    this.pageSize = ps;
                },
                props,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                searchType: this.searchType,
                searchTypeArr,
                defaultPageSize: this.defaultPageSize,
                listMap: this.listMap,
                message: this.message,
                extraData,
            });
        }

        /**
         * 渲染分页组件
         */
        renderPagination(pageSizeOptions?: any[], mapObj?: IPageMapObj): React.ReactNode {
            return <div className="paging">{renderPagination(this, pageSizeOptions, mapObj)}</div>;
        }

        /**
         * 渲染单个内容
         * @param item
         */
        renderOneItem(_item: any, ..._args: any[]): React.ReactNode {
            return null;
        }

        renderItems(items: any, ..._args: any[]): React.ReactNode {
            return (
                <>
                    <List className="list-compact" itemLayout="vertical" dataSource={items} renderItem={(item: any, i) => this.renderOneItem(item, i)} />
                    {this.renderPagination()}
                </>
            );
        }

        /**
         * 渲染列表（判断是否为空）
         * @param [items]
         * @returns
         */
        renderList(items, ..._args: any[]): React.ReactNode {
            return items ? items.length > 0 ? this.renderItems(items) : <NoData.Component /> : null;
        }

        renderBody(map = "items"): React.ReactNode {
            const { state } = this.props,
                items = state![map];
            return (
                // <Row gutter={16}>
                this.renderList(items)
                // </Row>
            );
        }

        render(): React.ReactNode {
            return (
                <div className="content">
                    <div className="container-view">{this.renderBody()}</div>
                    {this.renderLoading()}
                </div>
            );
        }
    }
}
