import { EffectsMapObject } from 'dva';
import { ReducersMapObject } from 'redux';
import { freeze } from 'immer';
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId } from '@reco-m/core';
import { app, getSearchUrl } from '@reco-w/core-ui';
import { tagService } from '@reco-m/tag-service';

import { specialModel } from '@reco-w/declare-models';
import { Namespaces as commonNamespaces } from '@reco-w/common-models';
import { CommonSearchParamInUrlEnum } from '@reco-w/common-common';
import { policyImplementationService, policySpecialService } from '@reco-w/policy-service';
import { Namespaces, POLICY_TAG_ARR } from './common';
import { getMultiSelectValues } from './utils';

export namespace newPolicyDeclareModel {
    export const namespace = Namespaces.newpolicyDeclare;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        ...specialModel.effects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: 'initState', message });
                yield yield put({ type: 'getDeclareMode', props, message });
                yield put({ type: 'getTagData', message });
                yield put({ type: 'getDataByUrl', props, message });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: '/policy', message });
            } catch (e) {
                yield call(message!.error, 'initPage：' + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                const tagUrlMaps = POLICY_TAG_ARR.map((x) => x.urlMap);
                const params = getSearchUrl([CommonSearchParamInUrlEnum.key, CommonSearchParamInUrlEnum.pageIndex, CommonSearchParamInUrlEnum.pageSize, ...tagUrlMaps], props);

                let tagParams: any = {};
                for (let item of POLICY_TAG_ARR) {
                    const values = getMultiSelectValues(params[item.urlMap]);
                    yield put({ type: 'input', data: { [item.valueMap]: values } });
                    tagParams[item.paramMap] = values;
                }

                yield put({
                    type: 'input',
                    data: {
                        key: params[CommonSearchParamInUrlEnum.key],
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 6,
                    },
                });

                const data = {
                    key: params[CommonSearchParamInUrlEnum.key],
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 6,
                    isValid: true,
                    isPublish: true,
                    detailIsAllowDeclare: true,
                    ...tagParams,
                };

                yield put({ type: 'getData', data, message });

                yield put({ type: 'input', data: { tabIndex: params[CommonSearchParamInUrlEnum.tabIndex] } });
            } catch (e) {
                yield call(message!.error, 'getDataByUrl：' + e.errmsg);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put, select }) {
            try {
                let state = yield select((state) => state[Namespaces.newpolicy]),
                    declareMode = state!.declareMode;

                if (!declareMode) {
                    yield yield put({ type: `${Namespaces.newpolicy}/getDeclareMode`, message });
                    state = yield select((state) => state[Namespaces.newpolicy]);
                    declareMode = state!.declareMode;
                }

                yield put({ type: 'input', data: { declareMode } });
            } catch (e) {
                yield call(message!.error, 'getDeclareMode：' + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getData({ message, data }, { call, put, select }) {
            try {
                yield put({ type: 'showLoading' });

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                // console.log('测试会员', memberCommonState);

                const result = yield call(policyImplementationService.getDeclareImplementationDetailList, {
                    customerId: certifyDetail?.companyId,
                    ...data,
                });

                yield put({ type: 'input', data: result });
            } catch (e) {
                yield call(message!.error, 'getData：' + e.errmsg);
            } finally {
                yield put({ type: 'hideLoading' });
            }
        },

        /**
         * 获取标签数据
         * @param { message, tagt }
         * @param { call, put }
         */
        *getTagData({ message }, { call, put }) {
            try {
                const tagClass = POLICY_TAG_ARR.map((x) => x.tagCode).join(',');
                const tagter = yield call(tagService.getTagByTagClasses, { tagClass, parkId: getCurrentParkId() });
                yield put({ type: 'input', data: { ...tagter } });
            } catch (e) {
                yield call(message!.error, 'getTagData：' + e.errmsg);
            }
        },

        /**
         * 获取标签数据
         * @param { message, tagt }
         * @param { call, put }
         */
        *getSpecialData({ message, data }, { call, put }) {
            try {
                yield put({ type: 'showLoading' });
                const result = yield call(policySpecialService.getSpecialDetailList, {
                    ...data,
                });

                yield put({ type: 'input', data: result });
            } catch (e) {
                yield call(message!.error, 'getTagData：' + e.errmsg);
            }
        },
    };
}
app.model(newPolicyDeclareModel);
