import { merge, set } from 'lodash';
import { encode } from 'base-64';

import { getObjectProp } from '../utils';

import { getApiUrl, getAuthUrl } from './transform.url';

const appDomain = encode(getObjectProp(client, 'appDomain') || setAppDomain());

function setAppDomain() {
    const url: any = getApiUrl() ?? 'api',
        authUrl: any = getAuthUrl() ?? 'api';

    if (!authUrl || url === authUrl) {
        return url;
    }
    let s = url.split('/');
    s.splice(s.length - 2);
    return s.join('/');
}

function non<T>(value: T) {
    return value !== null && value !== void 0;
}

function isFunc(key: string | (() => string)) {
    return typeof key === 'function';
}

function getIdentityKey(key: string | (() => string)) {
    return isFunc(key) ? (key as any)() : key;
}

function getKey(key: string | (() => string)) {
    return `${getIdentityKey(key)}_${appDomain}`;
}

function getValue(storage: Storage, key: string | (() => string)): string {
    return storage.getItem(getKey(key))!;
}

function setValue(storage: Storage, key: string | (() => string), value: string) {
    non(value) ? storage.setItem(getKey(key), value) : storage.removeItem(getKey(key));
}

function getObject<R = any>(storage: Storage, key: string | (() => string)): R {
    const json = storage.getItem(getKey(key));

    return json ? JSON.parse(json) : json;
}

function setObject<T>(storage: Storage, key: string | (() => string), value: T) {
    non(value) ? storage.setItem(getKey(key), JSON.stringify(value)) : storage.removeItem(getKey(key));
}

export function getSessionStorage(key: string | (() => string)): string {
    return getValue(sessionStorage, key);
}

export function setSessionStorage(key: string | (() => string), value: string): string {
    return setValue(sessionStorage, key, value), value;
}

export function getSessionStorageObject<R = any>(key: string | (() => string)): R {
    return getObject(sessionStorage, key);
}

export function setSessionStorageObject<T>(key: string | (() => string), value: T): T {
    return setObject(sessionStorage, key, value), value;
}

export function updateSessionStorageObject<T>(key: string | (() => string), value: T): T {
    return setObject(sessionStorage, key, (value = merge({}, getSessionStorageObject(key), value))), value;
}

export function setSessionStorageObjectKey<T>(key: string | (() => string), keyPath: string | string[], value: T): T {
    return setObject(sessionStorage, key, set(getSessionStorageObject(key), keyPath, value)), value;
}

export function deleteSessionStorageObjectKey(key: string | (() => string), keyPath: string | string[]): void {
    setSessionStorageObjectKey(key, keyPath, null);
}

export function removeSessionStorage(key: string | (() => string)): void {
    sessionStorage.removeItem(getKey(key));
}

export function clearSessionStorage(): void {
    sessionStorage.clear();
}

export function createSessionStorageScene(key: string | (() => string), keyPath: string | string[]) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? () => `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        setObjectKey<T>(value: T): T {
            return setSessionStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey() {
            deleteSessionStorageObjectKey(key, keyPath);
        },
    };
}

export function createSessionStorage(key: string | (() => string)) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? () => `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        has(): boolean {
            return !!getSessionStorage(key);
        },
        get(): string {
            return getSessionStorage(key);
        },
        set(value: string) {
            return setSessionStorage(key, value);
        },
        getObject<R = any>(): R {
            return getSessionStorageObject<R>(key);
        },
        setObject<T>(value: T): T {
            return setSessionStorageObject(key, value);
        },
        updateObject<T>(value: T) {
            return updateSessionStorageObject(key, value);
        },
        setObjectKey<T>(keyPath: string | string[], value: T): T {
            return setSessionStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey(keyPath: string | string[]) {
            deleteSessionStorageObjectKey(key, keyPath);
        },
        remove() {
            removeSessionStorage(key);
        },
        clear() {
            clearSessionStorage();
        },
        createScene(keyPath: string | string[]) {
            return createSessionStorageScene(key, keyPath);
        },
    };
}

export function getLocalStorage(key: string | (() => string)): string {
    return getValue(localStorage, key);
}

export function setLocalStorage(key: string | (() => string), value: string): string {
    return setValue(localStorage, key, value), value;
}

export function getLocalStorageObject<R = any>(key: string | (() => string)): R {
    return getObject(localStorage, key);
}

export function setLocalStorageObject<T>(key: string | (() => string), value: T): T {
    return setObject(localStorage, key, value), value;
}

export function updateLocalStorageObject<T>(key: string | (() => string), value: T): T {
    return setObject(localStorage, key, (value = merge({}, getLocalStorageObject(key), value))), value;
}

export function setLocalStorageObjectKey<T>(key: string | (() => string), keyPath: string | string[], value: T): T {
    return setObject(localStorage, key, set(getLocalStorageObject(key), keyPath, value)), value;
}

export function deleteLocalStorageObjectKey(key: string | (() => string), keyPath: string | string[]): void {
    setLocalStorageObjectKey(key, keyPath, null);
}

export function removeLocalStorage(key: string | (() => string)): void {
    localStorage.removeItem(getKey(key));
}

export function clearLocalStorage(): void {
    localStorage.clear();
}

export function createLocalStorageScene(key: string | (() => string), keyPath: string | string[]) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        setObjectKey<T>(value: T): T {
            return setLocalStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey() {
            deleteLocalStorageObjectKey(key, keyPath);
        },
    };
}

export function createLocalStorage(key: string | (() => string)) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        has(): boolean {
            return !!getLocalStorage(key);
        },
        get(): string {
            return getLocalStorage(key);
        },
        set(value: string) {
            return setLocalStorage(key, value);
        },
        getObject<R = any>(): R {
            return getLocalStorageObject<R>(key);
        },
        setObject<T>(value: T): T {
            return setLocalStorageObject(key, value);
        },
        updateObject<T>(value: T) {
            return updateLocalStorageObject(key, value);
        },
        setObjectKey<T>(keyPath: string | string[], value: T): T {
            return setLocalStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey(keyPath: string | string[]) {
            deleteLocalStorageObjectKey(key, keyPath);
        },
        remove() {
            removeLocalStorage(key);
        },
        clear() {
            clearLocalStorage();
        },
        createScene(keyPath: string | string[]) {
            return createLocalStorageScene(key, keyPath);
        },
    };
}

let memoryStorageKeys: any = {},
    memoryStorage = new WeakMap();

function getMemoryStorageKey(key: string | (() => string)) {
    const id = getIdentityKey(key);

    return memoryStorageKeys[id] || (memoryStorageKeys[id] = {});
}

function hasMemoryStorageKey(key: string | (() => string)) {
    return memoryStorageKeys[getIdentityKey(key)];
}

function removeMemoryStorageKey(key: string | (() => string)) {
    delete memoryStorageKeys[getIdentityKey(key)];
}

export function getMemoryStorage<R = any>(key: string | (() => string)) {
    return memoryStorage.get(getMemoryStorageKey(key)) as R;
}

export function setMemoryStorage<T>(key: string | (() => string), value: T): T {
    return memoryStorage.set(getMemoryStorageKey(key), value), value;
}

export function updateMemoryStorageObject<T>(key: string | (() => string), value: T): T {
    return memoryStorage.set(getMemoryStorageKey(key), (value = merge({}, getMemoryStorage(key), value))), value;
}

export function setMemoryStorageObjectKey<T>(key: string | (() => string), keyPath: string | string[], value: T): T {
    return memoryStorage.set(getMemoryStorageKey(key), set(getMemoryStorage(key), keyPath, value)), value;
}

export function deleteMemoryStorageObjectKey(key: string | (() => string), keyPath: string | string[]): void {
    setMemoryStorageObjectKey(key, keyPath, null);
}

export function removeMemoryStorage(key: string | (() => string)): void {
    memoryStorage.delete(getMemoryStorageKey(key));

    removeMemoryStorageKey(key);
}

export function clearMemoryStorage(): void {
    memoryStorageKeys = {};

    memoryStorage = new WeakMap();
}

export function createMemoryStorageScene(key: string | (() => string), keyPath: string | string[]) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        setObjectKey<T>(value: T): T {
            return setMemoryStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey() {
            deleteMemoryStorageObjectKey(key, keyPath);
        },
    };
}

export function createMemoryStorage(key: string | (() => string)) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        has(): boolean {
            return !!getMemoryStorage(key);
        },
        get(): string {
            return getMemoryStorage(key);
        },
        set(value: string) {
            return setMemoryStorage(key, value);
        },
        getObject<R = any>(): R {
            return getMemoryStorage<R>(key);
        },
        setObject<T>(value: T): T {
            return setMemoryStorage(key, value);
        },
        updateObject<T>(value: T) {
            return updateMemoryStorageObject(key, value);
        },
        setObjectKey<T>(keyPath: string | string[], value: T): T {
            return setMemoryStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey(keyPath: string | string[]) {
            deleteMemoryStorageObjectKey(key, keyPath);
        },
        remove() {
            removeMemoryStorage(key);
        },
        clear() {
            clearMemoryStorage();
        },
        createScene(keyPath: string | string[]) {
            return createMemoryStorageScene(key, keyPath);
        },
    };
}

export function getStorage(key: string | (() => string)) {
    return value<string>(key, [getMemoryStorage, setMemoryStorage], [getSessionStorage, setSessionStorage], [getLocalStorage, setLocalStorage]);
}

export function getStorageObject<R = any>(key: string | (() => string)) {
    return value<R>(key, [getMemoryStorage, setMemoryStorage], [getSessionStorageObject, setSessionStorageObject], [getLocalStorageObject, setLocalStorageObject]);
}

function value<R>(key: string | (() => string), ...storages: [(key: string | (() => string)) => R, any][]) {
    const setters: any[] = [];

    for (const [getter, setter] of storages) {
        const value = getter(key);

        if (non(value)) {
            setters.forEach((setter) => setter(key, value));

            return value;
        } else {
            setters.push(setter);
        }
    }
}

export function setStorage(key: string | (() => string), value: string): string {
    return getStorage(key), setLocalStorage(key, value), setSessionStorage(key, value), setMemoryStorage(key, value);
}

export function setStorageObject<T>(key: string | (() => string), value: T): T {
    return getStorageObject(key), setLocalStorageObject(key, value), setSessionStorageObject(key, value), setMemoryStorage(key, value);
}

export function updateStorageObject<T>(key: string | (() => string), value: T): T {
    return getStorageObject(key), updateLocalStorageObject(key, value), updateSessionStorageObject(key, value), updateMemoryStorageObject(key, value);
}

export function setStorageObjectKey<T>(key: string | (() => string), keyPath: string | string[], value: T): T {
    return getStorageObject(key), setLocalStorageObjectKey(key, keyPath, value), setSessionStorageObjectKey(key, keyPath, value), setMemoryStorageObjectKey(key, keyPath, value);
}

export function deleteStorageObjectKey(key: string | (() => string), keyPath: string | string[]): void {
    deleteLocalStorageObjectKey(key, keyPath), deleteSessionStorageObjectKey(key, keyPath), deleteMemoryStorageObjectKey(key, keyPath);
}

export function removeStorage(key: string | (() => string)): void {
    removeLocalStorage(key), removeSessionStorage(key), removeMemoryStorage(key);
}

export function clearStorage(): void {
    clearLocalStorage(), clearSessionStorage(), clearMemoryStorage();
}

export function createStorageScene(key: string | (() => string), keyPath: string | string[]) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        setObjectKey<T>(value: T): T {
            return setStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey() {
            deleteStorageObjectKey(key, keyPath);
        },
    };
}

export function createStorage(key: string | (() => string)) {
    const backKey = key;

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;
        },
        has(): boolean {
            return !!getStorage(key);
        },
        get(): string {
            return getStorage(key)!;
        },
        set(value: string) {
            return setStorage(key, value);
        },
        getObject<R = any>(): R {
            return getStorageObject<R>(key)!;
        },
        setObject<T>(value: T): T {
            return setStorageObject(key, value);
        },
        updateObject<T>(value: T) {
            return updateStorageObject(key, value);
        },
        setObjectKey<T>(keyPath: string | string[], value: T): T {
            return setStorageObjectKey(key, keyPath, value);
        },
        deleteObjectKey(keyPath: string | string[]) {
            deleteStorageObjectKey(key, keyPath);
        },
        remove() {
            removeStorage(key);
        },
        clear() {
            clearStorage();
        },
        createScene(keyPath: string | string[]) {
            return createStorageScene(key, keyPath);
        },
    };
}

export function storageSceneFactory(key: string | (() => string), keyPath: string | string[], ...storageCreaters: any[]) {
    const storages = storageCreaters.map((storageCreater) => storageCreater(key));

    return {
        resetKey(subKey: string) {
            storages.forEach((storage) => storage.resetKey(subKey));
        },
        setObjectKey<T>(value: T): T {
            return storages.forEach((storage) => storage.setObjectKey(keyPath, value)), value;
        },
        deleteObjectKey() {
            storages.forEach((storage) => storage.deleteObjectKey(keyPath));
        },
    };
}

export function storageFactory(key: string | (() => string), ...storageCreaters: any[]) {
    const backKey = key,
        storages = storageCreaters.map((storageCreater) => storageCreater(key));

    return {
        resetKey(subKey: string) {
            key = isFunc(backKey) ? `${(backKey as any)()}_${subKey}` : `${backKey}_${subKey}`;

            storages.forEach((storage) => storage.resetKey(subKey));
        },
        has(): boolean {
            return !!storages.last()?.has();
        },
        get(): string | void {
            for (const storage of storages) {
                const value = storage.get();

                if (non(value)) {
                    return value;
                }
            }
        },
        set(value: string) {
            return storages.forEach((storage) => storage.set(value)), value;
        },
        getObject<R = any>(): R | void {
            for (const storage of storages) {
                const value = storage.getObject();

                if (non(value)) {
                    return value;
                }
            }
        },
        setObject<T>(value: T): T {
            return storages.forEach((storage) => storage.setObject(value)), value;
        },
        updateObject<T>(value: T) {
            return storages.forEach((storage) => storage.updateObject(value)), value;
        },
        setObjectKey<T>(keyPath: string | string[], value: T): T {
            return storages.forEach((storage) => storage.setObjectKey(keyPath, value)), value;
        },
        deleteObjectKey(keyPath: string | string[]) {
            storages.forEach((storage) => storage.deleteObjectKey(keyPath));
        },
        remove() {
            storages.forEach((storage) => storage.remove());
        },
        clear() {
            storages.forEach((storage) => storage.clear());
        },
        createScene(keyPath: string | string[]) {
            return {
                setObjectKey<T>(value: T): T {
                    return storages.forEach((storage) => storage.setObjectKey(keyPath, value)), value;
                },
                deleteObjectKey() {
                    storages.forEach((storage) => storage.deleteObjectKey(keyPath));
                },
            };
        },
    };
}

export function createStorageByKey(key: string | (() => string)) {
    if (hasMemoryStorageKey(key)) {
        return createMemoryStorage(key);
    }

    if (sessionStorage.getItem(getKey(key))) {
        return createSessionStorage(key);
    }

    if (localStorage.getItem(getKey(key))) {
        return createLocalStorage(key);
    }
}
