import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getCurrentParkId } from "@reco-m/core";
import { app, getParkCode, getSearchUrl } from "@reco-w/core-ui";

import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberCompanyUserTypeService, memberService } from "@reco-w/member-service";
import { MemberManagerEnum, MemberRoleNameBuiltInEnum, Namespaces, operateSourceEnum, SearchParamInUrlEnum } from "./common";

export namespace memberManagerModel {
    export const namespace = Namespaces.memberManager;

    export type StateType = typeof state;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
        },
        !0
    );

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getUserTypes", message });
                yield yield put({ type: "getCompanyId", message });
                yield put({ type: "getCertifyListByUrl", message, props });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        *initPageDetail({ message, accountId, authenticationId, parkId, customerId, callback }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getUserTypes", message });
                yield yield put({ type: "getCompanyId", parkId, customerId, message, accountId, authenticationId, callback });
                yield put({ type: "getMember", parkId, customerId, message, accountId, authenticationId, callback });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取详情
         */
        *getMember({ message, accountId, authenticationId, callback, parkId }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                parkId = parkId || getCurrentParkId();
                const memberList = yield call(memberService.getMember, accountId, parkId || getCurrentParkId());

                const result = memberList?.find((x) => x.id === authenticationId);

                if (!result) {
                    yield put(routerRedux.replace(`/${getParkCode()}/my/myperson`));
                    message?.warning("该用户没有认证您的企业");
                    return;
                }
                result.authenticationId = result?.id;

                yield put({ type: "input", data: { member: result } });

                if (callback) {
                    yield call(callback, result);
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取企业Id
         */
        *getCompanyId({ message, isRefresh, parkId, customerId }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshMember: isRefresh });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user?.currentUser;

                let member = memberCommonState!.member;
                if (customerId && parkId) {
                    const memberList = yield call(memberService.getMember, user?.id, parkId);
                    member = memberList?.find((x) => x.companyId === customerId);
                }

                const isManager = member?.companyUserTypeName === MemberRoleNameBuiltInEnum.manager && member.status === CertifyStatusEnum.allow;

                if (!isManager) {
                    yield put(routerRedux.replace(`/${getParkCode()}/my`));
                    message?.warning("您没有权限浏览该页面");
                    return;
                }

                let companyId = member?.companyId;

                yield put({ type: "input", data: { isManager, companyId, userId: memberCommonState?.user?.currentUser?.id } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取列表
         */
        *getCertifyListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(
                    [CommonSearchParamInUrlEnum.pageIndex, CommonSearchParamInUrlEnum.pageSize, CommonSearchParamInUrlEnum.titleTab, SearchParamInUrlEnum.certifyStatus],
                    props
                );

                let titleTab = params[CommonSearchParamInUrlEnum.titleTab] || MemberManagerEnum.manage;

                const status =
                    titleTab === MemberManagerEnum.manage
                        ? Number(CertifyStatusEnum.allow)
                        : params[SearchParamInUrlEnum.certifyStatus] === Number(CertifyStatusEnum.allStatus)
                        ? null
                        : Number(params[SearchParamInUrlEnum.certifyStatus]);

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 10,
                        titleTab: titleTab,
                        status,
                    },
                });

                const data = {
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 10,
                    status,
                };

                yield put({ type: "getCertifyList", message, data: data });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取列表
         */
        *getCertifyList({ message, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const state = yield select((state) => state[Namespaces.memberManager]);

                let companyId = state!.companyId;

                const result = yield call(memberService.getCompanyStaffList, {
                    companyId,
                    parkId: data!.parkId,
                    pageIndex: data!.pageIndex,
                    pageSize: data!.pageSize,
                    status: data!.status === 0 ? null : data!.status,
                });

                yield put({ type: "input", data: { members: result.items, total: result.totalItems } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 更新认证状态
         */
        *certifyUpdate({ message, callBack, data }, { call, select }) {
            try {
                const state = yield select((state) => state[Namespaces.memberManager]);

                let params = {
                    ids: [data!.authenticationId],
                    parkId: data!.parkId,
                    rejectReason: state!.reason,
                    status: data!.status,
                    operateSource: operateSourceEnum.web,
                };

                yield call(memberService.certifyUpdate, params);

                yield call(callBack!);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取会员类型
         * @param { fail, companyUserTypeId }
         * @param { call, put }
         */
        *getUserTypes({ message }, { call, put }) {
            try {
                const usertypes = yield call(memberCompanyUserTypeService.getList, { isSupportFrontAuth: true });

                yield put({
                    type: "input",
                    data: { usertypes },
                });
            } catch (e) {
                yield call(message!.error, e.errmsg);
            }
        },

        /**
         * 修改认证角色
         * @param { message, callback }
         * @param { call, put, select }
         */
        *updateCompanyUserType({ message, callback }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.memberManager]);
                const selectedMember = state!.selectedMember;

                let result;
                if (selectedMember) {
                    result = yield call(memberService.updateCompanyUserType, selectedMember?.authenticationId, selectedMember.companyUserTypeId, operateSourceEnum.web);
                }

                yield call(callback!, result);
            } catch (e) {
                message!.error(`${e?.errmsg || e}`);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(memberManagerModel);
