import moment from "moment";

import "moment/locale/zh-cn";
export function getDateFormat(date: string | Date, fmt: string = "yyyy-MM-dd" , def?: Date): Date | undefined {
    let ndate = moment(date, fmt);
    return date instanceof Date ? date : date ? ndate.toDate() : def;
}
export function getDate(date: string | Date, def?: Date): Date | undefined {
    return date instanceof Date ? date : date ? new Date(date.replace(/\-/g, "/").replace(/T/g, " ").substr(0, 19)) : def;
}

export function formatNow(fmt: string = "yyyy-MM-dd") {
    return formatDate(new Date(), fmt);
}

export function formatDateTime(date: Date | string, fmt: string = "yyyy-MM-dd HH:mm:ss") {
    return formatDate(date, fmt);
}

// 只用于发送数据格式化数据
export function formatDateTimeSend(date: Date | string, fmt: string = "yyyy-MM-ddTHH:mm:ss") {
    return formatDate(date, fmt);
}

export function formatDate(date: Date | string, fmt: string = "yyyy-MM-dd"): string {
    const value = getDate(date);
    return value ? moment(value).format(fmt.replace(/dd/g, "DD").replace(/hh/g, "HH")) : (date as any);
}

/**
 * 获取当前日期N天后日期
 * @param days GetDateStr
 */
export function getDateStr(days?: any, fmt: string = "yyyy-MM-dd") {
    return formatDate(moment().add(days, "days").toDate(), fmt);
}

/**
 * 获取指定日期N天后日期
 * @param days GetDateStr
 */
export function getSetDateStr(date: Date | string, days: number, fmt: string = "yyyy-MM-dd") {
    return formatDate(moment(getDate(date)).add(days, "days").toDate(), fmt);
}

export function formatDates(fmt: string = "yyyy-MM-dd") {
    return (date: Date | string) => formatDate(date, fmt);
}

export function formatDateTimes(fmt: string = "yyyy-MM-dd HH:mm:ss") {
    return (date: Date | string) => formatDate(date, fmt);
}


export function formatTime(date: Date | string, fmt: string = "hh:mm") {
    return formatDate(date, fmt);
}

/**
 * 判断周几
 */
export function getWeekStr(date: string, days: number) {
    let week = moment(getDate(date)).add(days, "days").day();
    let str;
    if (week === 0) {
        str = "周日";
    } else if (week === 1) {
        str = "周一";
    } else if (week === 2) {
        str = "周二";
    } else if (week === 3) {
        str = "周三";
    } else if (week === 4) {
        str = "周四";
    } else if (week === 5) {
        str = "周五";
    } else if (week === 6) {
        str = "周六";
    }
    return str;
}
