import React from "react";
import PropTypes from "prop-types";

import { isEqual, isString } from "lodash";
import { Image } from "antd";

import { requireEnsureCss, getObjectProp, getAssetsUrl, htmlInjectEncode, transformAssetsUrl, PureComponent } from "@reco-m/core";

import { getAssetsHomeUrl } from "../util";

import { BiEditorTypeEnum } from "./editor-type.enum";
import { isRelativePath as ckeditorIsRelativePath } from "./ckeditor";
import { isRelativePath as ewebeditorIsRelativePath, appendChild } from "./ewebeditor";

const { enable, encodeRegexp, encodeRegexpFlags } = {
    enable: true,
    encodeRegexp: /<\/?(script|link|style|frameset|frame)\b[^>]*?>/gi,
    ...getObjectProp(client, "plugins.editorView.htmlInject", {}),
} as any;

const ASSETS_HOME_URL = getAssetsHomeUrl(),
    editorType: BiEditorTypeEnum = getObjectProp(client, "plugins.editor.editorType", BiEditorTypeEnum.ckeditor),
    assets = getObjectProp(client, "plugins.ckeditor.css", ASSETS_HOME_URL + "assets/js/ckeditor5/classic/25.0.0/ckedit5.min");

export namespace BiEditorView {
    export interface IProps extends PureComponent.IProps {
        html?: string;
        encode?: boolean;
        className?: string;
        component?: any;
        style?: any;
        editorType?: BiEditorTypeEnum;
    }

    export interface IState extends PureComponent.IState {
        open: boolean;
        imgs: string[];
        index: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static displayName = "BiEditorView";
        static propTypes = {
            data: PropTypes.string,
            editorType: PropTypes.string,
        };

        static defaultProps = {
            classPrefix: "html-content ck-content",
            component: "div",
            encode: enable,
            editorType,
        };

        protected baseUrl: string;
        protected contentRef = React.createRef<any>();

        state = { open: false, imgs: [], index: 0 } as unknown as S;

        constructor(props: P, context: any) {
            super(props, context);

            this.baseUrl = getAssetsUrl();

            if (props.editorType === BiEditorTypeEnum.ckeditor) {
                requireEnsureCss(assets);
            }
        }

        componentDidMount() {
            this.renderRef();
        }

        componentDidUpdate(prevProps: Readonly<P>) {
            if (prevProps.html !== this.props.html) {
                this.renderRef();
            }
        }

        shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>): boolean {
            const { props, state } = this;

            return !isEqual(nextProps, props) || !isEqual(nextState, state);
        }

        protected getContent() {
            let { html = "", encode } = this.props;

            if (html && (ewebeditorIsRelativePath || ckeditorIsRelativePath)) {
                html = html.replace(/(<\w+[^>]*\s+\b(?:src|href|url)\b=['"]?)~\/([^>]+>)/gi, `$1${this.baseUrl}$2`);
            }

            return encode ? htmlInjectEncode(html, isString(encodeRegexp) ? new RegExp(encodeRegexp, encodeRegexpFlags) : encodeRegexp)! : html;
        }

        protected renderRef = () => {
            const { current: container } = this.contentRef;

            this.resolveCKEditorMedia(container);

            if (container) {
                const { imgs } = this.state;

                imgs.clear();

                container.querySelectorAll("img").forEach((img, index) => {
                    const { src, style } = img;

                    if (src && (src.startsWith("./") || src.startsWith("~/"))) {
                        img.src = `${this.baseUrl},${src.substring(2)}`;
                    }

                    if (!getComputedStyle(img, null)["max-width"]) {
                        style.maxWidth = "100%";
                    }

                    imgs.push(img.src);

                    img.addEventListener("click", () => this.setState({ open: true, index }), false);
                });

                container.querySelectorAll("a").forEach((a) => {
                    const { href } = a;

                    if (href && (href.startsWith("./") || href.startsWith("~/"))) {
                        a.href = `${this.baseUrl}${href.substring(2)}`;
                    }
                });
            }
        };

        protected resolveCKEditorMedia(container: HTMLElement) {
            if (container && this.props.editorType === BiEditorTypeEnum.ckeditor) {
                container.querySelectorAll("oembed").forEach((oembed) => {
                    const url = oembed.getAttribute("url");

                    if (url) {
                        const { searchParams } = new URL(url, this.baseUrl || location.href),
                            parent = oembed.parentNode,
                            wrapper = document.createElement("div"),
                            [width, height] = [searchParams.get("width") ?? searchParams.get("w"), searchParams.get("height") ?? searchParams.get("h")];

                        wrapper.classList.add("ck-media__wrapper");

                        appendChild(
                            `<video ${width ? `width=${width}` : ""} ${height ? `height=${height}` : ""} controls autoplay loop><source src="${url}" type="video/mp4"></video>`,
                            wrapper
                        );

                        parent!.insertBefore(wrapper, oembed);
                        parent!.removeChild(oembed);
                    }
                });
            }
        }

        onCancel() {
            this.setState({ open: false });
        }

        render(): React.ReactNode {
            const { component: Component, className, style } = this.props as any,
                content = this.getContent();

            return (
                <>
                    <Component style={style} className={this.classnames(className, this.getClassSet())} dangerouslySetInnerHTML={{ __html: content }} ref={this.contentRef}></Component>
                    <Image.PreviewGroup
                        preview={{
                            visible: this.state.open,
                            onVisibleChange: (value) => {
                                this.setState({
                                    open: value
                                })
                            }
                        }}
                    >
                        {this.state.imgs &&
                            this.state.imgs.length > 0 &&
                            this.state.imgs.map((e, i) => {
                                return (
                                    <Image
                                        className="hide"
                                        key={i}
                                        src={transformAssetsUrl(e)}
                                    />
                                );
                            })}
                    </Image.PreviewGroup>
                </>
            );
        }
    }
}
