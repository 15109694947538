/** 获取指定Url中的指定参数 */
export function getQueryString(name, str) {
    const result = str && str.match(new RegExp('[?&]' + name + '=([^&]+)', 'i'));
    if (result == null || result.length < 1) return '';
    return result[1];
}

/** 获取当前Url中的指定参数 */
export function getLocationQueryString(name, location) {
    const result = location && location.search && location.search.match(new RegExp('[?&]' + name + '=([^&]+)', 'i'));
    if (result == null || result.length < 1) return '';
    return result[1];
}

/** url中需要转换的特殊字符 */
const specialChar = [
    { char: '@', regularChar: /\@/g, code: '%40', regularCode: /%40/g },
    { char: ':', regularChar: /\:/g, code: '%3A', regularCode: /%3A/g },
    { char: '$', regularChar: /\$/g, code: '%24', regularCode: /%24/g },
    { char: ',', regularChar: /\,/g, code: '%2C', regularCode: /%2C/g },
    { char: ';', regularChar: /\;/g, code: '%3B', regularCode: /%3B/g },
    { char: '+', regularChar: /\+/g, code: '%2B', regularCode: /%2B/g },
    { char: '=', regularChar: /\=/g, code: '%3D', regularCode: /%3D/g },
    { char: '?', regularChar: /\?/g, code: '%3F', regularCode: /%3F/g },
    { char: '"', regularChar: /\"/g, code: '%22', regularCode: /%22/g },
    { char: '/', regularChar: /\//g, code: '%2F', regularCode: /%2F/g },
    { char: '(', regularChar: /\(/g, code: '%28', regularCode: /%28/g },
    { char: ')', regularChar: /\)/g, code: '%29', regularCode: /%29/g },
    { char: '<', regularChar: /\</g, code: '%3C', regularCode: /%3C/g },
    { char: '>', regularChar: /\>/g, code: '%3E', regularCode: /%3E/g },
];

/** encoding的基础上，加上特殊字符的处理 */
export function standardEncoding(v: string): string {
    let value = encodeURIComponent(v);

    specialChar.map((item) => value.replace(item.regularChar, item.code));

    return value;
}

/** decoding的基础上，加上特殊字符的处理 */
export function standardDecoding(v: string): string {
    let value = decodeURIComponent(v);

    specialChar.map((item) => value.replace(item.regularCode, item.char));

    return value;
}

/** 获取当前Url参数并返回 */
export function getSearchUrl(params: any[], props: any) {
    const returnParams = {};
    const { location } = props;

    params &&
        params.length > 0 &&
        params.forEach((x: string) => {
            returnParams[x] = standardDecoding(getLocationQueryString(x, location) || '') || null;
        });
    return returnParams;
}

/**
 * 变更url参数
 * @param url 路由
 * @param params 参数对象
 * @param [isReset] 是否重置，如果为true，则清空路由中的参数后重新赋params中的值
 * @returns
 */
export function changeUrlParams(url: string, params: {}, isReset?) {
    let index = url.indexOf('?'),
        // 截取 "?" 之前的路由
        beforeIndexUrl = index > -1 ? url.substring(0, url.indexOf('?')) : url,
        // 截取 "?" 之后的路由
        afterIndexUrl = isReset ? '' : index > -1 ? url.substring(url.indexOf('?')) : '';

    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            let value = params[key],
                hasParams = afterIndexUrl ? true : false;

            // 判断value是否为空
            const isValueNull = (typeof value === 'string' && value.trim() === '') || value === null;
            value = isValueNull ? null : standardEncoding(value);

            // 当路由中已经存在该参数时，通过正则处理
            if (afterIndexUrl.indexOf('&' + key + '=') > -1 || afterIndexUrl.indexOf('?' + key + '=') > -1) {
                if (isValueNull) {
                    // 如果value为空，就删去该参数
                    let regex: any;
                    if (afterIndexUrl.indexOf('?' + key + '=') > -1) regex = new RegExp('(' + key + '=)([^&]*)+', 'ig');
                    else regex = new RegExp('(&' + key + '=)([^&]*)+', 'ig');

                    afterIndexUrl = afterIndexUrl.replace(regex, '');
                    afterIndexUrl = afterIndexUrl.replace('?&', '?'); // 当该参数为 "?" 后第一个参数时，删去后会形成 "?&" 字符串，要单独做处理
                } else {
                    // 如果value不为空，替换参数值
                    const regex = new RegExp('(' + key + '=)([^&]*)+', 'ig');
                    afterIndexUrl = afterIndexUrl.replace(regex, (_matchStr, g1) => g1 + value);
                }
            } else {
                if (!isValueNull) {
                    const symbol = hasParams ? '&' : '?';
                    afterIndexUrl = afterIndexUrl + symbol + key + '=' + value;
                }
            }
        }
    }

    url = beforeIndexUrl + afterIndexUrl;

    return url || '?';
}

/** 页面跳转 */
export function changePage(currentPage, pageSize, that) {
    that.goTo(changeUrlParams(window.location.search, { pi: currentPage, ps: pageSize }));

    window["scrollTop"]();;
}

/** 在使用相对路径跳转时，判断当前路由是否存在"/?"或当前路由的是否以"/"结尾，如果存在则返回"../"，指的是需要多返回一级路由 */
export function getbeforeUrl() {
    const isMoreUrl = judgebeforeUrl();
    return isMoreUrl ? '../' : '';
}

/** 判断当前路由是否存在"/?" */
export function judgebeforeUrl(url = window.location.href) {
    return url.indexOf('/?') > -1 || url.charAt(url.length - 1) === '/';
}

export function goToUrl(url: string, target?: string, features?: string) {
    return window.open(new URL(url, location.href), target, features);
}

/**
 * 截取至限制字数
 * @param str 需要去除属性的字符串
 * @param limitNum 限制字数
 * @param isRemoveHtml 控制是否去除html属性(默认为true)
 */
export function getLimitSummary(str: string, limitNum?: number, isRemoveHtml: boolean = true) {
    let summary = isRemoveHtml ? removeHtmlAttribute(str) : str;

    if (!limitNum) return summary;

    if (limitNum) {
        if (summary.length <= limitNum) return summary;
        else {
            summary = summary.slice(0, limitNum - 3);
            return summary + '······';
        }
    }
}

/**
 * html相关属性去除
 * @param str 需要去除属性的字符串
 */
export function removeHtmlAttribute(str: string) {
    if (str == null || str === undefined || !str) {
        return '';
    } else {
        const tem = str
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&apos;/g, "'")
            .replace(/&amp;/g, '&')
            .replace(/&nbsp;/g, '')
            .replace(/&ensp;/g, '')
            .replace(/<\/?.+?\/?>/g, '')
            .replace(/<[^>]+>/g, '');

        return tem;
    }
}

/**
 * 多行文本提交前将回车转换为<br/>
 * @param commentContent 多行文本
 */
export function formatTextareaContent(commentContent: string) {
    if (!commentContent) {
        return '';
    }

    commentContent = commentContent.replace(/[\n]{1,}/g, '<br/>');

    while (commentContent.indexOf('<br/>') === 0) {
        commentContent = commentContent.replace('<br/>', '');
    }

    return commentContent;
}

/** 数组转换为树结构，
 * @param arr：原数组
 * @param idMap：树数组中的ID 在原数组中的属性名，默认为ID，
 * @param labelMap： 树数组中的label 在原数组中的属性名，默认为TagName，
 * @param valueMap： 树数组中的value 在原数组中的属性名，默认为TagValue，
 * @param parentidMap： 寻找父对象的属性名，默认为ParentID
 */
export function arrToTree(arr: any[], idMap: string = 'id', labelMap: string = 'tagName', valueMap: string = 'tagValue', parentidMap: string = 'parentId') {
    let result: any = [];

    if (!Array.isArray(arr)) {
        return result;
    }

    let map = {};

    arr.forEach((item) => {
        delete item.children;
        map[item[idMap]] = item;
        item.label = item[labelMap];
        item.value = item[valueMap];
    });

    arr.forEach((item) => {
        let parent = map[item[parentidMap]];
        if (parent) {
            (parent.children || (parent.children = [])).push(item);
        } else {
            result.push(item);
        }
    });

    return result;
}
