import React, { forwardRef, useMemo } from 'react';
import { Options } from 'react-redux';
import { connect } from 'dva';

import { freeze } from 'immer';

import { CoreComponent } from '../container/core-component';

const pageMapping = new Map<any, any>();

export function replacePage<P extends CoreComponent.IProps, C = React.ComponentType<P>>(original: C, target: C): void {
    pageMapping.set(original, target);
}

export function template<P extends CoreComponent.IProps, R = any, C = React.ComponentType<P>>(
    component: C,
    mapStateToProps: (state: any) => R = (state: any) => state,
    getPrimaryReducer?: (state: R) => any,
    connectOptions?: Options<any, any, any, any>
): C {
    return forwardRef((props: P, ref) => {
        const target = useMemo(() => connect2(component, mapStateToProps, getPrimaryReducer, connectOptions), [component, mapStateToProps, getPrimaryReducer, connectOptions]);

        return React.createElement(target as any, { ...props, ref });
    }) as any;
}

function connect2<P extends CoreComponent.IProps, R = any, C = React.ComponentType<P>>(
    component: C,
    mapStateToProps: (state: any) => R = (state: any) => state,
    getPrimaryReducer?: (state: R) => any,
    connectOptions?: Options<any, any, any, any>
): C {
    return connect(
        (state: any) => {
            const nextState = mapStateToProps(state),
                primaryReducer = typeof getPrimaryReducer === 'function' && getPrimaryReducer(nextState);

            return { state: freeze(primaryReducer ? { ...primaryReducer, ...nextState } : nextState) };
        },
        (void 0)!,
        (void 0)!,
        connectOptions
    )(pageMapping.get(component) ?? (component as any)) as any;
}
