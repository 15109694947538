import React from "react";
import PropTypes from "prop-types";

import { ContextWatchdog } from "./ckeditor-utils";

export const ContextWatchdogContext = React.createContext<ContextWatchdog>(null!);

export namespace CKEditorContext {
    export interface IProps {
        id?: string;
        isLayoutReady?: boolean;
        context?: () => void;
        config?: any;
        onReady?: (c: () => void) => void;
        onError?: (error: any, p: any) => void;
        children: any
    }

    export interface IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends React.Component<P, S> {
        static displayName = "CKEditorContext";
        static propTypes = {
            id: PropTypes.string,
            isLayoutReady: PropTypes.bool,
            context: PropTypes.func,
            config: PropTypes.object,
            onReady: PropTypes.func,
            onError: PropTypes.func,
        };

        static defaultProps = {
            isLayoutReady: true,
            onError: (error, details) => console.error(error, details),
        };

        contextWatchdog: ContextWatchdog | null;

        constructor(props, context) {
            super(props, context);

            this.contextWatchdog = null;

            if (this.props.isLayoutReady) {
                this._initializeContextWatchdog(this.props.config);
            }
        }

        shouldComponentUpdate(nextProps) {
            if (nextProps.id !== this.props.id) {
                if (this.contextWatchdog) {
                    this.contextWatchdog.destroy();
                }

                this._initializeContextWatchdog(nextProps.config);
            }

            if (nextProps.isLayoutReady && !this.contextWatchdog) {
                this._initializeContextWatchdog(nextProps.config);

                return true;
            }

            return this.props.children !== nextProps.children;
        }

        componentWillUnmount() {
            this._destroyContext();
        }

        private _initializeContextWatchdog(config) {
            this.contextWatchdog = new ContextWatchdog(this.props.context);

            this.contextWatchdog.create(config).catch((error) => {
                this.props.onError!(error, {
                    phase: "initialization",
                    willContextRestart: false,
                });
            });

            this.contextWatchdog.on("error", (_, errorEvent) => {
                this.props.onError!(errorEvent.error, {
                    phase: "runtime",
                    willContextRestart: errorEvent.causesRestart,
                });
            });

            this.contextWatchdog.on("stateChange", () => {
                if (this.contextWatchdog!.state === "ready" && this.props.onReady) {
                    this.props.onReady(this.contextWatchdog!.context);
                }
            });
        }

        private _destroyContext() {
            if (this.contextWatchdog) {
                this.contextWatchdog.destroy();
                this.contextWatchdog = null;
            }
        }

        render() {
            return <ContextWatchdogContext.Provider value={this.contextWatchdog!}>{this.props.children}</ContextWatchdogContext.Provider>;
        }
    }
}
