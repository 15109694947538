import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";

import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { rateService } from "@reco-w/comment-service";

import { Namespaces, RateAuditStatusEnum, TopicRateTypeEnum } from "./common";

export namespace evaluateListModel {
    export const namespace = Namespaces.evaluateList;
    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );
    export type StateType = typeof state;

    let changeCounter = 0;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, bindData }, { call, put }) {
            try {
                yield put({ type: "initState" });
                if (bindData?.bindTableId && bindData?.bindTableName) {
                    yield put({ type: "getCommentsDetailAction", message, bindData });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 获取评价详情
         */
        *getCommentsDetailAction({ message, bindData, params }, { call, put, select }) {
            try {
                const thisChangeId = ++changeCounter,
                    result = yield call(rateService.getPaged, {
                        ...bindData,
                        pageSize: 5,
                        isPublic: true,
                        rateType: TopicRateTypeEnum.rate,
                        auditStatus: RateAuditStatusEnum.pass,
                        isShowWaitAudit: true,
                        ...params,
                    });
                if (thisChangeId >= changeCounter) {
                    if (result && result.currentPage <= 1) {
                        yield put({
                            type: "input",
                            data: { comments: result.items },
                        });
                    } else {
                        const state = yield select((state) => state[Namespaces.evaluateList]),
                            comments = state.comments || [];
                        let datas = comments.push(result.items);
                        yield put({
                            type: "input",
                            data: { comments: datas },
                        });
                    }
                }

                if (result && result.currentPage >= result.totalPages) {
                    yield put({ type: "input", data: { hasMore: false } });
                } else {
                    yield put({ type: "input", data: { hasMore: true } });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}

app.model(evaluateListModel);
