import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getCurrentParkId, getObjectProp } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { notificationSceneService, notificationService } from "@reco-w/notice-service";
import { MailBoxTypeEnum, Namespaces, NotificationTypesEnum } from "./common";

export namespace noticeCommonModel {
    export const namespace = Namespaces.noticeCommon;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 获取场景
         * @param { message }
         * @param { call, put }
         */
        *getScene({ message }, { call, put }) {
            try {
                const productCode = getObjectProp(client, "app.notice.productCode", ["SOC", "PSS"]);

                let scenes: any[] = [];
                for (let i = 0; i < productCode.length; i++) {
                    const productScene = yield call(notificationSceneService.getTakeScene, productCode[i]);
                    scenes = [...scenes, ...productScene];
                }

                const tagColor = ["blue", "orange", "green", "purple", "magenta", "red", "gold", "lime", "cyan", "geekblue"];

                scenes.forEach((item, i) => {
                    item.color = tagColor[i % tagColor.length];
                });

                const sceneIds = scenes?.map((x) => x.id) || [];

                yield put({ type: "input", data: { scenes, sceneIds } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取未读消息
         * @param { message }
         * @param { call, put, select }
         */
        *getUnreadNotification({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${Namespaces.memberCommon}/getCurrentUser`, message });
                const memberCommonState = yield select((state) => state[Namespaces.memberCommon]),
                    user = memberCommonState!.user,
                    currentUser = user && user!.currentUser;

                if (!currentUser) {
                    yield put({ type: "input", data: { notice: null, noticeCount: 0 } });
                    return;
                }
                let state = yield select((state) => state[Namespaces.noticeCommon]),
                    scenes = state!.scenes,
                    sceneIds = state!.sceneIds;

                if (!scenes?.length) {
                    yield yield put({ type: `getScene`, message });
                    state = yield select((state) => state[Namespaces.noticeCommon]);
                    scenes = state!.scenes;
                    sceneIds = state!.sceneIds;
                }

                const result = yield call(notificationService.getPaged, {
                    ownerId: currentUser.id,
                    isRead: false,
                    notificationType: NotificationTypesEnum.unRead,
                    mailbox: MailBoxTypeEnum.mailBox2,
                    parkId: getCurrentParkId(),
                    sceneId: sceneIds,
                    pageSize: 1,
                });

                yield put({ type: "input", data: { notice: result, noticeCount: result.totalItems } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}

app.model(noticeCommonModel);
