import React from "react";

import { Upload, message, Image, Button } from "antd";
import { InfoCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { UploadrWrap } from "@reco-m/core";

export namespace UploadWrap {
    export interface IProps extends UploadrWrap.IProps {
        id?: string;
        customUpload?: boolean;
        picture?: boolean;
        accept?: WebUploader.FilePicker.accept | string;
        isShowTip?: boolean;
        tipText?: string;
        uploadText?: string | React.ReactNode;
        prefix?: React.ReactNode;
        suffix?: React.ReactNode;
        readonly?: boolean;
    }

    export interface IState extends UploadrWrap.IState {
        files: any[];
        percent?: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends UploadrWrap.Base<P, S> {
        static displayName = "UploadWrap";
        protected uploadRef = React.createRef<any>();

        showErrorMessage(content: string): void {
            message.error(content, 3);
        }

        protected onFileUploadProgress(file: any, percent: number) {
            const { current } = this.uploadRef;

            if (current) {
                file.status = "uploading";

                current.onProgress({ percent: Math.round(percent * 100) }, file);
            }
        }

        onChange({ file, event }: { file: any; fileList: any[]; event: any }) {
            if (file.isRemove) {
                this.removeFile(file);
            } else if (!event) {
                const { files } = this.state;

                file.percent = 0;
                file.status = "uploading";

                this.props.maxCount
                    ? (
                        files.length > 0 && this.removeFile(files[0]),
                        this.uploadFiles([file])
                    )
                    : this.checkUploadFiles(
                          () => files.length + 1,
                          () => file.size + files.reduce((a, file) => a + file.size, 0),
                      ) && this.uploadFiles([file]);
            }
        }

        onRemove(file: any) {
            file.isRemove = true;
        }

        getShowUploadList() {
            const { readonly } = this.props as any;

            return {
                showPreviewIcon: true,
                showRemoveIcon: !readonly,
                showDownloadIcon: true,
            };
        }

        renderUpload(): React.ReactNode {
            const { attachUploadService: uploadService, props: rawProps } = this,
                { className, multiple, children, readonly, isShowTip, tipText, prefix, suffix, ...props } = rawProps as any;
            return (
                <>
                    {prefix}

                    {isShowTip && !readonly && (
                        <div className="size-12 text-red margin-bottom-xs">
                            <InfoCircleOutlined />
                            {tipText || "支持上传JPG、PNG、GIF格式图片"}
                        </div>
                    )}

                    <Upload
                        showUploadList={this.getShowUploadList()}
                        {...props}
                        ref={this.uploadRef}
                        accept={uploadService?.mimeTypes}
                        className={this.classnames(className, this.getClassSet(), readonly ? "readonly" : "")}
                        beforeUpload={this.beforeUpload.bind(this)}
                        fileList={this.state.files}
                        multiple={multiple}
                        onPreview={this.onPreview.bind(this)}
                        onChange={this.onChange.bind(this)}
                        onDownload={this.onDownload.bind(this)}
                        disabled={readonly}
                        onRemove={this.onRemove.bind(this)}
                    >
                        {this.renderUploadBtn()}
                        {children}
                        {suffix}
                    </Upload>
                </>
            );
        }

        renderUploadText() {
            const { uploadText = "上传" } = this.props as any;

            return uploadText;
        }

        renderUploadBtn(): React.ReactNode {
            const { customUpload, readonly, picture, maxCount } = this.props as any;

            return maxCount ? (
                this.state.files.length > 0 ? (
                    <div className="bi-images" style={{ backgroundImage: `url(${this.state.files[0].url})` }} />
                ) : customUpload || readonly ? null : picture ? (
                    <div>
                        <PlusOutlined />
                        <div>{this.renderUploadText()}</div>
                    </div>
                ) : (
                    <Button>
                        <PlusCircleOutlined />
                        {this.renderUploadText()}
                    </Button>
                )
            ) : customUpload || readonly ? null : picture ? (
                <div>
                    <PlusOutlined />
                    <div>{this.renderUploadText()}</div>
                </div>
            ) : (
                <Button>
                    <PlusCircleOutlined />
                    {this.renderUploadText()}
                </Button>
            );
        }

        renderModalBody(): React.ReactNode {
            return (
                this.attachDataService.previewImages
                    .filter((f) => f.url)
                    .map((data, i) => {
                        return (
                            <Image className="hide" src={data.url} alt={data.fileName || data.name} key={i}/>
                        );
                    })
            );
        }

        renderModal() {
            return (
                <Image.PreviewGroup
                    preview={{
                        visible: this.attachDataService.previewVisible,
                        onVisibleChange: (value) => {
                           !value && this.onCancel()
                        }
                    }}
                >
                    {this.renderModalBody()}
                </Image.PreviewGroup>
            );
        }
    }
}
