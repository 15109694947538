import React from "react";
import { TimePicker, DatePicker, Input, Checkbox, Radio, Select, List } from "antd";
import moment from "moment";
import { formatDate } from "@reco-m/core";
import { deepClone } from "@reco-w/core-ui";

const { TextArea } = Input;
const { Option } = Select;

export interface FormDateProps {
    timeType: "time" | "date"; // 标题
    stateKey: string; // 输入值存入变量
    disabled?: boolean;
    format?: string;
    placeholder?: string;
    readOnly?: boolean;
}
export interface FormInputProps {
    stateKey: string; // 输入值存入变量
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
}
export interface FormTextAreaProps {
    stateKey: string; // 输入值存入变量
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    rows?: number;
}
export interface FormOptionConfigProps {
    stateKey: string; // 输入值存入变量
    disabled?: boolean;
    readOnly?: boolean;
    layout?: "horizontal" | "vertical"
}
export interface FormSelectOptionConfigProps {
    stateKey: string; // 输入值存入变量
    disabled?: boolean;
    mode?: "multiple" | "tags";
    readOnly?: boolean;
}
export interface FormOptionProps {
    label: string;
    value: string | number;
    readOnly?: boolean;
}

let _this;
class EfficientForm {
    formResult: any = {};
    constructor(__this) {
        _this = __this;
    }
    getResult() {
        return this.formResult;
    }
    setResult(result) {
        this.formResult = result;
        _this.dispatch({ type: "input", data: { random: Math.random() } });
    }
    /**
     * 是否选中
     * @param selects
     * @param item
     */
    checkSelect(selects, item) {
        let itm = selects.find((it) => it.value === item.value);
        return itm;
    }
    onCheckboxChange(arr, item, stateKey) {
        if (this.checkSelect(arr, item)) {
            arr = arr.filter((itm) => {
                return itm.value !== item.value;
            });
        } else {
            arr.push(item);
        }
        this.formResult[stateKey] = arr;
        _this.dispatch({ type: "input", data: { formResult: deepClone(this.formResult) } });
    }
    onRadioChange(arr, item, stateKey) {
        arr = [];
        arr.push(item);
        this.formResult[stateKey] = arr;
        _this.dispatch({ type: "input", data: { formResult: deepClone(this.formResult) } });
    }

    renderCheckbox(boxs: FormOptionProps[], config: FormOptionConfigProps) {
        if (config.layout === "horizontal" || !config.layout) {
            return <div className="form-checkbox-wrapper">
                {
                    boxs &&
                    boxs.map((item) => {
                        let result = this.formResult[config.stateKey] || [];
                        return (
                            <Checkbox
                                disabled={config.disabled || config.readOnly}
                                key={item.value}
                                value={item.value}
                                checked={this.checkSelect(result, item)}
                                onChange={() => {
                                    this.onCheckboxChange(result, item, config.stateKey);
                                }}
                            >
                                {item.label}
                            </Checkbox>

                        );
                    })
                }

            </div>
        } else {
            return (
                boxs &&
                boxs.map((item) => {
                    let result = this.formResult[config.stateKey] || [];

                    return (
                        <Checkbox
                            disabled={config.disabled || config.readOnly}
                            key={item.value}
                            value={item.value}
                            checked={this.checkSelect(result, item)}
                            onChange={() => {
                                this.onCheckboxChange(result, item, config.stateKey);
                            }}
                        >
                            {item.label}
                        </Checkbox>
                    );
                })
            );
        }

    }
    renderRadios(boxs: FormOptionProps[], config: FormOptionConfigProps) {
        if (config.layout === "horizontal" || !config.layout) {
            return (

                boxs &&
                boxs.map((item, index) => {
                    let result = this.formResult[config.stateKey] || [];

                    return (
                        <Radio
                            disabled={config.disabled || config.readOnly}
                            key={item.value}
                            value={item.value}
                            checked={this.checkSelect(result, item)}
                            name={"radio-" + index}
                            onChange={() => {
                                this.onRadioChange(result, item, config.stateKey);
                            }}
                        >
                            {item.label}
                        </Radio>
                    );
                })
            );
        } else {
            return (
                <List className="form-radio-wrapper">
                    {boxs &&
                        boxs.map((item, index) => {
                            let result = this.formResult[config.stateKey] || [];

                            return (
                                <Radio
                                    disabled={config.disabled || config.readOnly}
                                    key={item.value}
                                    value={item.value}
                                    checked={this.checkSelect(result, item)}
                                    name={"radio-" + index}
                                    onChange={() => {
                                        this.onRadioChange(result, item, config.stateKey);
                                    }}
                                >
                                    {item.label}
                                </Radio>
                            );
                        })}
                </List>

            );
        }

    }
    getSelectValue(result) {
        return result && result.map((item) => item.value);
    }
    getSelectLabel(result) {
        return result && result.map((item) => item.label).join(",");
    }
    renderSelect(boxs: FormOptionProps[], config: FormSelectOptionConfigProps) {
        let result = this.formResult[config.stateKey] || [];

        if (config.mode === "multiple") {
            if (config.readOnly) {
                return <div>{this.getSelectLabel(result)}</div>;
            }
            return (
                <Select
                    disabled={config.disabled}
                    mode={config.mode}
                    value={this.getSelectValue(result)}
                    onChange={(v: any) => {
                        let arr: any = [];
                        v &&
                            v.map((itm) => {
                                let filter = boxs.find((item) => {
                                    return `${item.value}` === `${itm}`;
                                });
                                arr.push(filter);
                            });

                        this.formResult[config.stateKey] = arr;
                        _this.dispatch({ type: "input", data: { formResult: deepClone(this.formResult) } });
                    }}
                >
                    {boxs &&
                        boxs.map((option) => {
                            return (
                                <Option value={option.value} key={option.value}>
                                    {option.label}
                                </Option>
                            );
                        })}
                </Select>
            );
        } else {
            if (config.readOnly) {
                return <div>{result.label}</div>;
            }
            return (
                <Select
                    disabled={config.disabled}
                    value={result.value}
                    onChange={(v: any) => {
                        let filter = boxs.find((item) => {
                            return `${item.value}` === `${v}`;
                        });

                        this.formResult[config.stateKey] = filter;
                        _this.dispatch({ type: "input", data: { formResult: deepClone(this.formResult) } });
                    }}
                >
                    {boxs &&
                        boxs.map((option) => {
                            return (
                                <Option value={option.value} key={option.value}>
                                    {option.label}
                                </Option>
                            );
                        })}
                </Select>
            );
        }
    }
    renderTextArea(param: FormTextAreaProps) {
        if (param.readOnly) {
            return <div>{this.formResult[param.stateKey]}</div>;
        }
        return (
            <TextArea
                disabled={param.disabled}
                value={this.formResult[param.stateKey]}
                placeholder={`${param.placeholder || "请输入"}`}
                data-seed="logId"
                rows={param.rows || 3}
                onChange={(e) => {
                    this.formResult[param.stateKey] = e.target.value;
                    _this.dispatch({ type: "input", data: { formResult: this.formResult } });
                }}
            />
        );
    }
    renderInput(param: FormInputProps) {
        // console.log("this.formResult", this.formResult);

        if (param.readOnly) {
            return <div>{this.formResult[param.stateKey]}</div>;
        }
        return (
            <Input
                disabled={param.disabled}
                value={this.formResult[param.stateKey]}
                type={param.type || "text"}
                placeholder={`${param.placeholder || "请输入"}`}
                onChange={(e) => {
                    this.formResult[param.stateKey] = e.target.value;
                    _this.dispatch({ type: "input", data: { formResult: this.formResult } });
                }}
            />
        );
    }
    renderDate(param: FormDateProps) {
        if (param.timeType === "time") {

            let props = {
                style: { width: "100%" },
                disabled: param.disabled,
                format: param.format,
                onChange: (v: any) => {
                    this.formResult[param.stateKey] = formatDate(v.toDate(), param.format || "hh:mm:ss") || null;
                    _this.dispatch({ type: "input", data: { formResult: this.formResult } });
                },
                placeholder: param.placeholder || "请选择",
            };
            return (
                <>
                    <TimePicker {...props} value={this.formResult[param.stateKey] && moment("1990-1-1 " + this.formResult[param.stateKey])}></TimePicker>
                </>
            );
        } else {

            let props = {
                style: { width: "100%" },
                disabled: param.disabled,
                format: param.format,
                onChange: (v: any) => {
                    this.formResult[param.stateKey] = formatDate(v.toDate(), param.format || "yyyy-MM-dd hh:mm:ss") || null;
                    _this.dispatch({ type: "input", data: { formResult: this.formResult } });
                },
                placeholder: param.placeholder || "请选择",
            };
            return (
                <>
                    <DatePicker {...props} value={this.formResult[param.stateKey] && moment(this.formResult[param.stateKey])} />
                </>
            );
        }
    }
}
export { EfficientForm };

/* 实例
eficientForm = new EfficientForm(this);
<Form className="form-default-style label-row survey-list-form">
    <Form.Item label="时间">
        {this.eficientForm.renderDate({
            timeType: "time",
            stateKey: "timeValue",
        })}
    </Form.Item>
    <Form.Item label="日期">
        {this.eficientForm.renderDate({
            timeType: "date",
            stateKey: "dateValue",
        })}
    </Form.Item>
    <Form.Item label="文本框">
        {this.eficientForm.renderInput({
            stateKey: "inputValue",
        })}
    </Form.Item>
    <Form.Item label="文本框">
        {this.eficientForm.renderTextArea({
            stateKey: "textAreaValue",
            rows: 4,
        })}
    </Form.Item>
    <Form.Item label="单选框">
        {this.eficientForm.renderRadios(
            [
                {
                    label: "标题1",
                    value: "123",
                },
                {
                    label: "标题2",
                    value: "234",
                },

                {
                    label: "标题3",
                    value: "345",
                },

                {
                    label: "标题4",
                    value: "456",
                },
            ],
            {
                stateKey: "radioValue",
            }
        )}
    </Form.Item>
    <Form.Item label="多选框">
        {this.eficientForm.renderCheckbox(
            [
                {
                    label: "标题1",
                    value: "123",
                },
                {
                    label: "标题2",
                    value: "234",
                },

                {
                    label: "标题3",
                    value: "345",
                },

                {
                    label: "标题4",
                    value: "456",
                },
            ],
            {
                stateKey: "checkboxValue",
            }
        )}
    </Form.Item>
    <Form.Item label="下拉多选">
        {this.eficientForm.renderSelect(
            [
                {
                    label: "标题1",
                    value: "标题1",
                },
                {
                    label: "标题2测",
                    value: "标题2测",
                },

                {
                    label: "标题3传",
                    value: "标题3传",
                },

                {
                    label: "标题4",
                    value: "标题4",
                },
            ],
            {
                stateKey: "selectMulValue",
                mode: "multiple",
            }
        )}
    </Form.Item>
    <Form.Item label="下拉单选">
        {this.eficientForm.renderSelect(
            [
                {
                    label: "标题1",
                    value: "123",
                },
                {
                    label: "标题2测",
                    value: "234",
                },

                {
                    label: "标题3传",
                    value: "345",
                },

                {
                    label: "标题4",
                    value: "456",
                },
            ],
            {
                stateKey: "selectValue",
                mode: "tags",
            }
        )}
    </Form.Item>
</Form>;
*/
