import { HttpService, resolveService } from "@reco-m/core";
export class AppModuleHttpService extends HttpService {
    protected configPromise: any;
    constructor() {
        super("app/module");
    }

    /**
     * 获取高德配置
     */
    getAMapConfig() {
        this.configPromise || (this.configPromise = this.httpGet("getConfig"));

        return this.configPromise || Promise.resolve({});
    }

    /**
     * 清除高德地图配置
     */
    clearAMapConfig() {
        delete this.configPromise;
    }

    /**
     * 重新获取高德地图配置
     */
    refreshAMapConfig() {
        return this.clearAMapConfig(), this.getAMapConfig();
    }
}
export const appModuleService = resolveService(AppModuleHttpService);

export class AppConfigHttpService extends HttpService {
    protected configPromise: any;
    constructor() {
        super("app/config");
    }

    /**
     * 获取配置
     */
    getConfig() {
        this.configPromise || (this.configPromise = this.httpGet("get-config"));

        return this.configPromise || Promise.resolve({});
    }

    /**
     * 清除配置
     */
    clearAMapConfig() {
        delete this.configPromise;
    }

    /**
     * 重新获取配置
     */
    refreshAMapConfig() {
        return this.clearAMapConfig(), this.getConfig();
    }
}
export const appConfigService = resolveService(AppConfigHttpService);

export class ConfigHttpService extends HttpService {
    protected configPromise: any;
    constructor() {
        super("config/config");
    }

    /**
     * 获取单个配置
     */
    getConfigGroup(data) {
        return this.httpGet('by-configgroup', this.resolveRequestParams(data));
    }

    /**
     * 获取配置Map
     */
    // getConfigGroups(data) {
    //     this.configPromise || (this.configPromise = this.httpGet('by-configgroups', this.resolveRequestParams(data)));
    //     return this.configPromise || Promise.resolve({});
    // }
    /**
     * 获取配置Map
     */
    getConfigGroups(data) {
        return this.httpGet('by-configgroups', this.resolveRequestParams(data));
    }
}
export const configService = resolveService(ConfigHttpService);

