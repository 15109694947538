import { isFunction, get } from 'lodash';

function transform(url?: string) {
    if (url && url.startsWith('//')) {
        let baseUrl = getApiUrl(),
            protocol = baseUrl.split('//')[0];
        return protocol + url;
    }
    return url && url.charAt(0) === '~' ? ((url = url.substr(2)), `${getApiUrl()}${url}`) : url;
}

export function isDevMode() {
    return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
}

export const resolveEnvConfigProp = (() => {
    let envConfig:any = get(server, 'envConfig');

    if (envConfig) {
        const env = getEnv();

        if (env in envConfig) {
            envConfig = envConfig[env];
        } else {
            const { hostname, port } = location;

            envConfig = envConfig[`${hostname}:${port}`] ?? envConfig[hostname];
        }

        if (envConfig) {
            return (name: string): string => get(envConfig, name) ?? get(server, name);
        }
    }

    return (name: string) => get(server, name);

    function getEnv() {
        let env =
            new URLSearchParams(location.search).get('env') ||
            Object.keys(envConfig).find((key) => {
                const { test } = envConfig[key];

                if (test instanceof RegExp) {
                    return test.test(location.href);
                } else if (isFunction(test)) {
                    return test();
                }
            });

        if (!env) {
            const { hostname } = location;

            if (isDevMode() && hostname === 'localhost') {
                env = 'local';
            } else if (hostname === 'app.bitechdevelop.com') {
                env = 'dev';
            } else if (hostname === 'fat.bitechdevelop.com') {
                env = 'test';
            } else {
                env = 'prod';
            }
        }

        return env;
    }
})();

export function getApiUrl() {
    return resolveEnvConfigProp('url') ?? server.url ?? '';
}

export function getAuthUrl() {
    return resolveEnvConfigProp('authUrl') ?? server.authUrl ?? getApiUrl();
}

export function transformUrl(url?: string, def?: string) {
    return url && (transform(url) || transform(def));
}

export function transformImageUrl(url: string, width: number, height: number, def?: string) {
    const v = 2;
    return url && `${transformAssetsUrl(url, def)}?width=${width * v}&height=${height * v}`;
}

function transformAssets(url?: string) {
    if (url && url.startsWith('//')) {
        let baseUrl = getAssetsUrl(),
            protocol = baseUrl.split('//', 1)[0];
        return protocol + url;
    }
    return url && url.charAt(0) === '~' ? ((url = url.substr(2)), `${getAssetsUrl()}${url}`) : url;
}

export function getAssetsUrl() {
    return resolveEnvConfigProp('assetsUrl') ?? server.assetsUrl ?? getApiUrl();
}

export function transformAssetsUrl(url?: string, def?: string) {
    return url && (transformAssets(url) || transformAssets(def));
}
