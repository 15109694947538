import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getCurrentParkId, getCurrentParkName } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberService, customerService, memberCompanyUserTypeService } from "@reco-w/member-service";

import { Namespaces, CustomerTypeEnum, CustomerStatusEnum, operateSourceEnum } from "./common";
export namespace certifyFormModel {
    export const namespace = Namespaces.certifyForm;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
        PageIndex: 1,
        isInRequest: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, inputData, callback, isUseSubmitDisabled, certifyId }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "input", data: { ...inputData } });
                yield yield put({ type: "getMemberConfig", message });
                yield put({ type: "getMember", message, callback, isUseSubmitDisabled, certifyId });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                // yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取会员类型
         * @param { fail, companyUserTypeId }
         * @param { call, put }
         */
        *getUserTypes({ fail, companyUserTypeId }, { call, put }) {
            try {
                const usertypes = yield call(memberCompanyUserTypeService.getList, { isSupportFrontAuth: true });

                let usertype;
                if (companyUserTypeId) {
                    usertype = usertypes.find((x) => x.id === companyUserTypeId);
                    yield put({ type: "input", data: { companyUserTypeId: usertype && companyUserTypeId } });
                }

                yield put({
                    type: "input",
                    data: { usertypes, usertype },
                });
            } catch (message) {
                fail!(message.errmsg);
            }
        },

        /**
         * 获取会员配置信息
         */
        *getMemberConfig({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.configCommon}/getMemberConfig`, message });
                const configCommonState: any = yield select((state) => state[commonNamespaces.configCommon]),
                    memberConfig = configCommonState!.memberConfig;

                yield put({ type: "input", data: { memberConfig } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "input", data: { isSwitching: false } });
            }
        },

        /**
         * 获取当前会员
         * @param { message, callback, isUseSubmitDisabled }
         * @param { call, put, select }
         */
        *getMember({ message, callback, isUseSubmitDisabled, certifyId }, { call, put, select }) {
            try {
                const state: any = yield select((state) => state[Namespaces.certifyForm]),
                    memberConfig = state!.memberConfig;

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshMember: true });
                const memberState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    memberList = memberState!.memberList,
                    user = memberState.user;
                let member = memberState.member;
                yield put({ type: "input", data: { memberList } });

                if ((memberList?.length || 0) >= (memberConfig?.parkEnterpriseCertifiedMaxNum || 0) && member?.status === CertifyStatusEnum.allow) {
                    // 当已认证企业数大于等于最大认证数时
                    yield put({ type: "input", data: { submitSuccess: true } });
                }

                if (!certifyId && !isUseSubmitDisabled) {
                    member = null;
                }

                if ([CertifyStatusEnum.allow, CertifyStatusEnum.noConfirm].contains(member?.status) && isUseSubmitDisabled) {
                    yield put({ type: "input", data: { isSubmited: true } });
                }

                yield put({ type: "getUserTypes", message, companyUserTypeId: member?.companyUserTypeId });
                yield put({ type: "getInitCustomer", message, companyId: !member?.isNewCompanyAuthentication && member?.companyId, companyName: member?.companyName });

                yield put({
                    type: "input",
                    data: {
                        Account: user && user.currentUser,
                        realname: (user && user.currentUser && user.currentUser.realName) || "",
                        mobile: (user && user.currentUser && user.currentUser.mobile) || "",
                        currentUser: user && user.currentUser,
                        member,
                        // companyUserTypeId: member?.companyUserTypeId,
                        userId: user && user.currentUser && user.currentUser.id,
                    },
                });

                if (!member?.isNewCompanyAuthentication && member?.companyId) {
                    yield put({
                        type: "input",
                        data: { companyId: member?.companyId, companyName: member?.companyName },
                    });
                }

                yield yield put({
                    type: "getCustomer",
                    message,
                    memberList,
                    selectedCompany: !member?.isNewCompanyAuthentication && member?.companyId && { customerId: member?.companyId, customerName: member?.companyName },
                });

                if (callback) {
                    yield call(callback, member);
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 认证
         * @param { message, callback, updatamemberID }
         * @param { call, put, select }
         */
        *certify({ message, callback, updatamemberID }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, message });

                const state: any = yield select((state) => state[Namespaces.certifyForm]),
                    usertype = state!.usertype;

                let params2 = {
                    accountId: state!.userId,
                    // companyDepartment: department,
                    companyId: state!.company && state!.company.customerId,
                    companyName: state!.company && state!.company.customerName,
                    companyUserTypeId: usertype.id,
                    companyUserTypeName: usertype.name,
                    parkId: getCurrentParkId(),
                    parkName: getCurrentParkName(),
                    realName: state!.realname,
                    mobile: state!.mobile,
                    operateSource: operateSourceEnum.web,
                };
                // const certify = yield call(memberService.certify, params2);
                let certify;
                if (updatamemberID) {
                    certify = yield call(memberService.put, updatamemberID, params2);
                } else {
                    certify = yield call(memberService.post, params2);
                }
                yield call(callback, certify);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取初始化的企业
         * @param { params, message, companyId, companyName }
         * @param { call, put }
         */
        *getInitCustomer({ params, message, companyId, companyName }, { call, put }) {
            try {
                if (companyId) {
                    const data = yield call(customerService.getPaged, {
                        parkId: getCurrentParkId(),
                        status: CustomerStatusEnum.deal,
                        customerType: CustomerTypeEnum.company,
                        key: companyName,
                        skipAuditPark: true,
                        ...params,
                    });

                    let company = data?.items?.find((x) => x.customerId === companyId);

                    yield put({ type: "input", data: { company } });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取企业
         * @param { params, message, selectedCompany}
         * @param { call, put, select }
         */
        *getCustomer({ params, message, selectedCompany, memberList }, { call, put, select }) {
            try {
                let state = yield select((state) => state[Namespaces.certifyForm]),
                    { companyList } = state;
                if (!memberList) {
                    memberList = state!.memberList;
                }
                let passMemberList = (memberList || state!.memberList || []).filter((x) => [CertifyStatusEnum.allow, CertifyStatusEnum.noConfirm].contains(x.status));

                if (selectedCompany) {
                    yield put({ type: "input", data: { companyList: [selectedCompany] } });
                } else {
                    passMemberList = (memberList || state!.memberList || []).filter((x) =>
                        [CertifyStatusEnum.bounced, CertifyStatusEnum.allow, CertifyStatusEnum.noConfirm].contains(x.status)
                    );
                }

                let data = yield call(customerService.getPaged, {
                    parkId: getCurrentParkId(),
                    status: CustomerStatusEnum.deal,
                    customerType: CustomerTypeEnum.company,
                    skipAuditPark: true,
                    pageSize: 20,
                    ...params,
                });

                let result: any = data.items;

                if (companyList && data.currentPage > 1) {
                    result.unshift(...companyList);
                }

                const selfCompanyList = result.filter((x) => !passMemberList.some((a) => a.companyId === x.customerId));

                yield put({ type: "input", data: { companyList: selfCompanyList, TotalPages: data.totalPages, isInRequest: false } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}
app.model(certifyFormModel);
