import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, pictureService } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { CommonSearchParamInUrlEnum, IParkBindTableNameEnum } from "@reco-w/common-common";
import { policyService } from "@reco-w/policy-service";
import { Namespaces, PolicyDeclareDetailTabIndexEnum } from "./common";

export namespace newPolicyDetailModel {
    export const namespace = Namespaces.newpolicyDetail;

    export const state: any = freeze({
        ...CoreState,
        showLoading: true,
        tabIndex: PolicyDeclareDetailTabIndexEnum.policy,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getPolicy", message, id, callback });
                yield put({ type: "getPolicyPicture", id, message });

            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.tabIndex], props);

                yield put({ type: "input", data: { tabIndex: params[CommonSearchParamInUrlEnum.tabIndex] || PolicyDeclareDetailTabIndexEnum.policy } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取政策
         * @param { message, id }
         * @param { call, put }
         */
        *getPolicy({ message, id, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(policyService.get, id);
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/policy", title: result?.title, message });

                yield put({ type: "input", data: { policy: result } });

                if (callback) {
                    yield call(callback, result);
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取活动图片
         * @param { message, id }
         * @param { call, put }
         */
        *getPolicyPicture({ message, id }, { call, put }) {
            try {
                console.log('id', id);
                
                const attachSrcArr = yield call(pictureService.getPictureList, {
                    bindTableName: IParkBindTableNameEnum.policy,
                    bindTableId: id,
                    customType: 10,
                });

                yield put({ type: "input", data: { attachSrcArr } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}
app.model(newPolicyDetailModel);
