import React from "react";
import { router } from "dva/";

import { Breadcrumb } from "antd";

import { PureComponent } from "@reco-m/core";
import { getParkCode } from "../util/utils";

export namespace Breadcrumbs {
    export interface IProps extends PureComponent.IProps {
        breadcrumbList: any;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends Breadcrumbs.IProps = Breadcrumbs.IProps, S extends Breadcrumbs.IState = Breadcrumbs.IState> extends PureComponent.Base<P, S> { 
        static displayName = "Breadcrumbs"; 
        render(): React.ReactNode {
            const { breadcrumbList } = this.props;

            const list = breadcrumbList ? breadcrumbList.map((item, i) => {
                return i < breadcrumbList.length - 1 ? (
                    {
                        title: <React.Fragment key={i}>
                            {item.router ? <router.Link to={item.router}>{item.name}</router.Link> : item.name}
                        </React.Fragment>
                    }
                ) : [];
            }) : []

            const item: any = [
                {
                    title: (
                        <router.Link to={`/${getParkCode()}`}>
                            <i className="icon iconhome-g size-14" /> <span>首页</span>
                        </router.Link>
                    ),
                },
                ...list,
                breadcrumbList
                    ? {
                          title: breadcrumbList[breadcrumbList.length - 1].name,
                      }
                    : null,
            ].filter((d) => d !== null);

            return (
                <Breadcrumb className="reco-breadcrumb" items={item} />
            );
        }
    }
}
