import React from "react";
import { router } from "dva";
import { Affix, Menu, Modal, Badge, Popover, Divider } from "antd";
import { BellOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";

import { template, setLocalStorage, getLocalStorage, getObjectProp, transformAssetsUrl, setCurrentUnit, getCurrentUnitId, getCurrentParkId } from "@reco-m/core";
import { changeUrlParams, ViewComponent, mtaH5Click, statisticsEvent, InputDelay } from "@reco-w/core-ui";

import { CommonSearchParamInUrlEnum, IS_OPEN_ENTERPRISE_HOME, IS_OPEN_RECRUIT } from "@reco-w/common-common";
import { Namespaces, headerModel, getStatisticArticle } from "@reco-w/layout-models";

export namespace PageHeader {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {}

    export interface IState extends ViewComponent.IState, headerModel.StateType {
        navState?: any;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        excludeSplit = ["/policy?"];

        get logoutRouter() {
            return getObjectProp(client, "logoutRouter", "/login");
        }

        get parkcode() {
            return this.props.match!.params.parkcode;
        }

        get defaultTitle() {
            return getObjectProp(client, "title", "--");
        }

        componentDidMount() {
            this.dispatch({
                type: "initPage",
                inputData: { radioSelect: getCurrentParkId() },
                judgeParkCode: this.parkcode,
            });

            $(window).scroll(() => {
                let top = $(window).scrollTop() as any;
                top >= 83
                    ? this.setState({
                          navState: true,
                      })
                    : this.setState({
                          navState: false,
                      });
            });
        }

        componentReceiveProps(nextProps: P) {
            let isLogin =
                (nextProps.location?.search?.includes("isLogin=") && !this.props.location?.search?.includes("isLogin=")) ||
                (this.props.location?.search?.includes("isLogin=") && !nextProps.location?.search?.includes("isLogin="));

            if (isLogin) {
                this.dispatch({ type: "getCertify" });
            }

            if (nextProps.location?.hash?.includes(this.logoutRouter)) {
                this.goTo(this.logoutRouter);
                window.location.reload();
            }
        }

        componentWillUnmount() {
            this.setState = () => {
                return;
            };
        }

        /**
         * 切换园区
         * @param park
         */
        changePark(park: any, unit) {
            if (unit && unit.id) {
                setCurrentUnit(unit);
            }

            if (park) {
                mtaH5Click(statisticsEvent.parkSwitch);
                setLocalStorage("selectedMenu", "/home"); // 设置被选中的菜单为home

                let parkcode = this.parkCode;
                let router = location.href.split(parkcode);

                this.dispatch({
                    type: "changePark",
                    message: this.message,
                    isLogin: this.isAuth(),
                    park,
                    callback: () => {
                        location.href = `${router[0]}${park.code}${router[1]}`;
                    },
                });
            }
        }

        /**
         * 渲染已登录时的展示按钮
         * @returns auth login
         */
        renderAuthLogin(): React.ReactNode {
            const { state } = this.props,
                user = state!.user;

            return (
                <>
                    <router.Link
                        to={`/${this.parkCode}/my`}
                        onClick={() => {
                            setLocalStorage("selectedMenu", "/my");
                            setLocalStorage("mySelectedMenu", "/my");
                        }}
                    >
                        {user?.nickName || "请设置昵称"}
                    </router.Link>
                    <Divider type={"vertical"} />
                    <router.Link
                        to={`/${this.parkCode}/login`}
                        onClick={() =>
                            this.dispatch({
                                type: `logout`,
                                success: () => this.goTo(`/${this.parkCode}/login`),
                                error: (e) => Modal.error({ title: "消息提醒", content: e }),
                            })
                        }
                    >
                        注销
                    </router.Link>
                </>
            );
        }

        /**
         * 渲染未登录时的展示按钮
         * @returns unauth login
         */
        renderUnauthLogin(): React.ReactNode {
            return (
                <>
                    <router.Link to={`/${this.parkCode}/login`}> 登录</router.Link>丨<router.Link to={`/${this.parkCode}/login/register`}>注册</router.Link>
                </>
            );
        }

        /**
         * 渲染登录按钮
         * @returns
         */
        renderLogin(): React.ReactNode {
            return this.isAuth() ? this.renderAuthLogin() : this.renderUnauthLogin();
        }

        /**
         * 渲染消息数量
         */
        renderNotice() {
            const { state } = this.props,
                noticeCount = state!.noticeCount;
            return this.isAuth() ? (
                <a className="margin-right-xl" onClick={() => this.goTo(`/${this.parkCode}/my/mynotice`)}>
                    <Badge count={noticeCount} size="small" overflowCount={9} offset={[8, 0]}>
                        <BellOutlined />
                    </Badge>
                </a>
            ) : null;
        }

        /**
         * 渲染搜索栏
         * @returns
         */
        renderSearch(): React.ReactNode {
            const { state } = this.props,
                searchKey = state!.searchKey;
            return (
                <div className="home-search">
                    <InputDelay.Component
                        prefix={<SearchOutlined />}
                        placeholder="输入关键字搜索"
                        style={{ width: 220 }}
                        value={searchKey}
                        onChange={(e) => this.dispatch({ type: "input", data: { searchKey: e.target.value } })}
                        onDelayTrigger={(value) => this.searchKey(value)}
                        hideIcon
                    />
                </div>
            );
        }

        /**
         * 渲染园区选择
         * @returns parks
         */
        renderParks(): React.ReactNode {
            const { state } = this.props,
                _parkNames = state!.parkNames || [],
                units = state!.units || [],
                radioSelect = getCurrentParkId() || state!.radioSelect,
                unitId = getCurrentUnitId(),
                selectPark = _parkNames.find((x) => x.value === radioSelect),
                appConfig = state!.appConfig,
                hidePop = units && _parkNames && units.length === 1 && _parkNames.length === 1;

            const content = (
                <div className="park-select">
                    <div className="park-select-box">
                        {units &&
                            units.length > 0 &&
                            units.map((unit, i) => {
                                return (
                                    <div className="park-select-content" key={"unit_" + i}>
                                        <div className="park-select-title">{unit.shortName || unit.unitName}</div>
                                        <div>
                                            {unit.parks.map((item, j) => (
                                                <div key={"unit_park_" + i + j} className="park-radio-box" onClick={() => this.changePark(item, unit)}>
                                                    <span className={"park-radio" + (item.value === radioSelect && unitId === unit.id ? " park-radio-checked" : "")}>
                                                        <span className="park-radio-input"></span>
                                                        <span
                                                            className={"park-radio-inner" + (item.value === radioSelect && unitId === unit.id ? " park-radio-inner-checked" : "")}
                                                        ></span>
                                                    </span>
                                                    <span className="park-radio-text" title={item.label}>
                                                        {item.label}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            );
            return !hidePop ? (
                selectPark && (
                    <>
                        {appConfig && <span className="interval">|</span>}
                        <Popover
                            placement="bottom"
                            content={content}
                            overlayClassName={"park-select-overlay"}
                            title={
                                <>
                                    <i className="icon iconbuilding margin-right-xs text-gray size-14"></i>选择园区
                                </>
                            }
                            trigger="hover"
                        >
                            <a title={selectPark && selectPark.label}>
                                {selectPark && selectPark.label} <DownOutlined style={{ fontSize: "12px" }} />
                            </a>
                        </Popover>
                    </>
                )
            ) : (
                <a title={selectPark && selectPark.label}>{selectPark && selectPark.label}</a>
            );
        }

        /**
         * logo
         */
        renderWebLogo(): React.ReactNode {
            const { state } = this.props,
                appConfig = state!.appConfig;

            return appConfig ? (
                <div className="head-logo">
                    <img src={(appConfig?.webLogo && transformAssetsUrl(appConfig?.webLogo)) || "assets/images/logo2.png"} />
                </div>
            ) : null;
        }

        /**
         * 渲染最上栏
         * @returns
         */
        renderParkAndLogin(): React.ReactNode {
            const { state } = this.props,
                appConfig = state!.appConfig;

            return (
                <div className="nav">
                    <div className="container-view">
                        <div className="flex">
                            {this.renderWebLogo()}
                            <div className="flex-item">
                                <div className="content-left">
                                    <span className="title">{appConfig ? appConfig?.webName || this.defaultTitle : ""}</span>
                                    {this.renderParks()}
                                </div>
                            </div>
                            <div className="flex">
                                {this.renderNotice()}
                                {this.renderLogin()}
                                {this.renderSearch()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        /**
         * 获取选中的目录
         * @param menuKey
         * @returns
         */
        getSelectedKeys(menuKey) {
            if (menuKey) {
                return [menuKey];
            }

            const selectedMenuText = getLocalStorage("selectedMenu");

            let selectedMenu = selectedMenuText;
            let selectedMenus = selectedMenuText && selectedMenuText.split("?");

            if (!this.excludeSplit.some((x) => selectedMenuText?.includes(x))) {
                selectedMenu = (selectedMenus && selectedMenus.length > 0 && selectedMenus[0]) || "";
            } else {
                if (selectedMenus && selectedMenus.length > 0) {
                    return [selectedMenus[0] + this.props.location?.search || ""];
                }
            }
            return [selectedMenu || ""];
        }

        /**
         * 渲染单条目录样式
         * @param key
         * @param link
         * @param event
         * @param title
         * @param [flag]
         * @returns menu item
         */
        renderMenuItem(key, link, event, title, flag?): React.ReactNode {
            return (
                <router.Link
                    key={key}
                    to={link}
                    className={flag ? "" : ""}
                    onClick={() => {
                        mtaH5Click(event);
                    }}
                >
                    {title}
                </router.Link>
            );
        }

        /**
         * 渲染下拉目录标题
         * @param title
         * @returns
         */
        renderSubMenuTitle(title) {
            return <span>{title}</span>;
        }

        /**
         * 点击目录时
         * @param e
         */
        onClickMenu(e) {
            if (e?.key?.length > 0) {
                this.dispatch({ type: "input", data: { menuKey: e.key } });
            }
        }

        /**
         * 渲染目录栏
         * @returns
         */
        renderMenu(): React.ReactNode {
            const { state } = this.props,
                menuKey = state!.menuKey,
                parkCode = this.parkCode,
                articleCatalogs = state!.articleCatalogs,
                selectedKeys: any[] = this.getSelectedKeys(menuKey),
                openEnterprise = IS_OPEN_ENTERPRISE_HOME,
                openRecruit = IS_OPEN_RECRUIT;

            const itemDynamic =
                articleCatalogs?.length > 0
                    ? articleCatalogs.map((item) => {
                          return {
                              label: this.renderMenuItem(
                                  `/article/${item.catalogCode}`,
                                  `/${parkCode}/article/${item.catalogCode}`,
                                  getStatisticArticle(item.catalogCode, "navigationBar"),
                                  item.catalogName,
                              ),
                              key: `/article/${item.catalogCode}`,
                          };
                      })
                    : [];

            const item = [
                {
                    label: this.renderMenuItem("/home", `/${parkCode}`, statisticsEvent.navigationBarHome, "首页", true),
                    key: "/home",
                },
                {
                    label: this.renderSubMenuTitle("园区动态"),
                    key: `park_dynamics`,
                    children: [
                        {
                            label: this.renderMenuItem("/impression", `/${parkCode}/impression`, statisticsEvent.navigationBarImpression, "园区印象"),
                            key: "/impression",
                        },
                        ...itemDynamic,
                        {
                            label: this.renderMenuItem("/activity", `/${parkCode}/activity`, statisticsEvent.navigationBarActivity, "活动中心"),
                            key: `/activity`,
                        },
                        {
                            label: this.renderMenuItem("/circle", `/${parkCode}/circle`, statisticsEvent.navigationBarCircle, "话题动态"),
                            key: `/circle`,
                        },
                    ],
                },
                {
                    label: this.renderSubMenuTitle("基础服务"),
                    key: `basic_service`,
                    children: [
                        {
                            label: this.renderMenuItem("/resource/roommeet", `/${parkCode}/resource/roommeet`, statisticsEvent.navigationBarResourceOrder, "资源预订"),
                            key: `/resource/roommeet`,
                        },
                        {
                            label: this.renderMenuItem("/workorder/wybx", `/${parkCode}/workorder/wybx`, statisticsEvent.navigationBarApplication, "物业报修"),
                            key: `/workorder/wybx`,
                        },
                        {
                            label: this.renderMenuItem("/workorder/xqtb", `/${parkCode}/workorder/xqtb`, statisticsEvent.navigationBarDemand, "需求提报"),
                            key: `/workorder/xqtb`,
                        },
                        {
                            label: this.renderMenuItem("/workorder/wyts", `/${parkCode}/workorder/wyts`, statisticsEvent.navigationBarComplaint, "物业投诉"),
                            key: `/workorder/wyts`,
                        },
                        {
                            label: this.renderMenuItem("/survey", `/${parkCode}/survey`, statisticsEvent.navigationBarResearch, "问卷调研"),
                            key: `/survey`,
                        },
                    ],
                },
                {
                    label: this.renderSubMenuTitle("政策服务"),
                    key: `policy_service`,
                    children: [
                        {
                            label: this.renderMenuItem(
                                `/policy?${CommonSearchParamInUrlEnum.tabIndex}=1`,
                                `/${parkCode}/policy?${CommonSearchParamInUrlEnum.tabIndex}=1`,
                                statisticsEvent.navigationBarPolicyDeclare,
                                "政策申报",
                            ),
                            key: `/policy?${CommonSearchParamInUrlEnum.tabIndex}=1`,
                        },
                        {
                            label: this.renderMenuItem(
                                `/policy?${CommonSearchParamInUrlEnum.tabIndex}=2`,
                                `/${parkCode}/policy?${CommonSearchParamInUrlEnum.tabIndex}=2`,
                                statisticsEvent.navigationBarPolicyMatch,
                                "政策匹配",
                            ),
                            key: `/policy?${CommonSearchParamInUrlEnum.tabIndex}=2`,
                        },
                        {
                            label: this.renderMenuItem(
                                `/policy?${CommonSearchParamInUrlEnum.tabIndex}=3`,
                                `/${parkCode}/policy?${CommonSearchParamInUrlEnum.tabIndex}=3`,
                                statisticsEvent.navigationBarPolicyCalculator,
                                "政策计算器",
                            ),
                            key: `/policy?${CommonSearchParamInUrlEnum.tabIndex}=3`,
                        },
                    ],
                },
                {
                    label: this.renderSubMenuTitle("企业服务"),
                    key: `enterprise_service`,
                    children: [
                        {
                            label: this.renderMenuItem("/market/all", `/${parkCode}/market/all`, statisticsEvent.navigationBarInstitution, "服务机构"),
                            key: `/market/all`,
                        },
                        {
                            label: this.renderMenuItem("/product/all", `/${parkCode}/product/all`, statisticsEvent.navigationBarProduct, "服务产品"),
                            key: `/product/all`,
                        },
                        openEnterprise
                            ? {
                                  label: this.renderMenuItem("/enterprise", `/${parkCode}/enterprise`, statisticsEvent.navigationBarHomepage, "企业主页"),
                                  key: `/enterprise`,
                              }
                            : null,
                        openRecruit
                            ? { label: this.renderMenuItem("/recruit", `/${parkCode}/recruit`, statisticsEvent.navigationBarRecruitment, "企业招聘"), key: `/recruit` }
                            : null,
                    ],
                },
                {
                    label: this.renderMenuItem("/my", `/${parkCode}/my`, statisticsEvent.navigationBarMy, "个人中心", true),
                    key: `/my`,
                },
            ].filter((d) => d !== null);
            return <Menu selectedKeys={selectedKeys} rootClassName={"head-select"} mode="horizontal" items={item} onClick={(e) => this.onClickMenu(e)} />;
        }

        /**
         * 关键字搜索
         * @param value
         */
        searchKey(value: string) {
            if (value && value.trim()) {
                mtaH5Click(statisticsEvent.globalSearch);
                this.goTo(changeUrlParams(`/${this.parkCode}/heardsearch`, { [CommonSearchParamInUrlEnum.globalKey]: value }));
            } else {
                if (location.pathname.indexOf("heardsearch") > -1) {
                    this.goTo(`/${this.parkCode}`);
                } else {
                    window.location.reload();
                }
            }
        }

        render(): React.ReactNode {
            return (
                <div className={this.classnames("head-menu", this.state.navState && "head-hide")}>
                    <Affix offsetTop={0}>
                        <div>
                            {this.renderParkAndLogin()}
                            <div className="header">
                                <div className="container-view">{this.renderMenu()}</div>
                            </div>
                        </div>
                    </Affix>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
