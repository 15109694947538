import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { IParkBindTableNameEnum, IS_OPEN_ENTERPRISE_HOME, IS_OPEN_RECRUIT } from "@reco-w/common-common";
import { parkCateService } from "@reco-w/impression-service";
import { ARTICLE_ROOT_CODE } from "@reco-w/article-models";
import { collectionHttpService } from "@reco-w/favorites-service";
import { enterpriseService } from "@reco-w/enterprise-service";
import { recruitService } from "@reco-w/recruit-service";

import { FAVORITE_TABS, Namespaces } from "./common";

export namespace favoritesModel {
    export const namespace = Namespaces.favorites;

    let articleCatalogs = [];

    export type StateType = typeof state;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
        favourities: null,
        chosenFavourite: IParkBindTableNameEnum.activity,
        currentPage: 1,
        pageSize: 10,
    });

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { put, call }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getArticleCatalog" });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取服务联盟类型
         * @param { message }
         * @param { call, put, select }
         */
        *getArticleCatalog({ message }, { call, put }) {
            try {
                const openRecruit = IS_OPEN_RECRUIT,
                    openEnterpriseHome = IS_OPEN_ENTERPRISE_HOME;
                if (!articleCatalogs?.length) {
                    articleCatalogs = yield call(parkCateService.getList, { authorized: false, rootCode: ARTICLE_ROOT_CODE });
                }

                const tabList: any[] = FAVORITE_TABS.concat();
                openEnterpriseHome && tabList.push({ tab: "企业主页", key: IParkBindTableNameEnum.companyHomePage, show: IS_OPEN_ENTERPRISE_HOME });
                openRecruit && tabList.push({ tab: "企业招聘", key: IParkBindTableNameEnum.recruit, show: IS_OPEN_RECRUIT });

                articleCatalogs?.length > 0 &&
                    articleCatalogs.forEach((item: any) => {
                        tabList.push({ tab: item?.catalogName, key: IParkBindTableNameEnum.article + "_" + item.catalogCode, code: item.catalogCode });
                    });

                yield put({ type: "input", data: { tabList } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 分页获取收藏列表
         */
        *getUserFollowDetail({ data, error }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let result;
                if (data.bindTableName === IParkBindTableNameEnum.companyHomePage) {
                    result = yield call(enterpriseService.getCollectionPaged, data);
                } else if (data.bindTableName === IParkBindTableNameEnum.recruit) {
                    result = yield call(recruitService.getCollectionPaged, data);
                } else {
                    result = yield call(collectionHttpService.getCollectionPaged, data);
                }
                yield put({
                    type: "input",
                    data: {
                        favourities: result.items,
                        favouritiesTotal: result.totalItems,
                    },
                });
            } catch (e) {
                yield call(error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 取消收藏
         */
        *unFollow({ data, message, callback }, { call }) {
            try {
                const result = yield call(collectionHttpService.cancelCollection, {
                    bindTableName: data!.bindTableName,
                    bindTableId: data!.bindTableId,
                });

                yield call(callback!, result);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}

app.model(favoritesModel);
