import React from "react";
import classNames from "classnames";
import { Image, ImageProps } from "antd";
import { FIT_TYPE, RATIO_TYPE } from "./images.auto";
import { mergeProps } from "../util";

export interface BiImageProps extends ImageProps {
    ratio?: RATIO_TYPE;
    type?: FIT_TYPE;
}

export const BiImage = (p: BiImageProps) => {
    const defaultProps = {
        type: "cover",
        ratio: "16:9",
    };

    const props = mergeProps(defaultProps, p);

    let ratioClassName: string;
    switch (props.ratio) {
        case "16:9":
            ratioClassName = "long-rectangle";
            break;
        case "4:3":
            ratioClassName = "rectangle";
            break;
        case "2:3":
            ratioClassName = "vertical-rectangle";
            break;
        case "1:1":
            ratioClassName = "square";
            break;
        default:
            ratioClassName = "";
            break;
    }

    return <Image {...props} rootClassName={classNames(props.rootClassName, 'reco-image',ratioClassName, props.type)} />;
};

BiImage.displayName = "BiImage";
