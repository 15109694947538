import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { newCouponHttpService } from "@reco-w/coupon-service";

import { Namespaces } from "./common";

export namespace couponGetModel {
    export const namespace = Namespaces.couponGet;
    export const state: any = freeze({
        ...CoreState,
        selectID: {},
        selectCouponTypeID: [],
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 领取优惠券
         * @param { error, data, gainSuccess }
         * @param { call, put, select }
         */
        *gainCoupon({ error, id, data, gainSuccess, map }, { call, put, select }) {
            try {
                yield put({ type: `${map ? `${map}/` : ""}input`, data: { isGainLoading: true } });

                yield yield put({ type: `${userNamespaces.user}/getCurrentUser`, error });
                const state = yield select((state) => state[userNamespaces.user]),
                    currentUser = state!.currentUser;
                const result = yield call(newCouponHttpService.gainCoupon, id, { userId: currentUser.id, userName: currentUser.nickName, ...data });
                if (result && gainSuccess) {
                    yield call(gainSuccess);
                }
            } catch (e) {
                yield call(error, "" + e.errmsg);
                yield put({ type: `${map ? `${map}/` : ""}input`, data: { isGainLoading: false } });
            } finally {
                if (map === Namespaces.couponGet) {
                    yield put({ type: `${map ? `${map}/` : ""}input`, data: { isGainLoading: false } });
                }
            }
        },
    };
}
app.model(couponGetModel);
