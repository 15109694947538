import { router } from "dva";
import { ConfigProvider, App } from "antd";
import zhCN from "antd/locale/zh_CN";
import "dayjs/locale/zh-cn";

import { getParkCode, loadLazyModule } from "@reco-w/core-ui";

function RouterConfig({ history }: any) {
    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                hashed: false,
                cssVar: { key: "bi-var" },
                token: {
                    paddingSM: 8,
                    padding: 12,
                    paddingLG: 16,
                    paddingXS: 4,
                    margin: 12,
                    marginLG: 16,
                    marginSM: 8,
                    colorBorder: "#e6e6e6",
                    colorSplit: "#e6e6e6",
                },
                components: {
                    Descriptions: {
                        titleMarginBottom: 8,
                        itemPaddingBottom: 4,
                    },
                    List: {
                        titleMarginBottom: 8,
                        itemPadding: "12px",
                        metaMarginBottom: 12,
                    },
                },
            }}
        >
            <App>
                <router.Router history={history}>
                    <router.Switch>
                        <router.Route path="/login" component={loadLazyModule(() => import(/* webpackChunkName: "login" */ "@reco-w/auth-login"))} />
                        <router.Route path="/:parkcode/login" component={loadLazyModule(() => import(/* webpackChunkName: "login" */ "@reco-w/ld-auth-login"))} />
                        <router.Route exact path="/:parkcode" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/ld-home-home"))} />
                        <router.Route path="/:parkcode/activity" component={loadLazyModule(() => import(/* webpackChunkName: "activity" */ "@reco-w/activity-activity"))} />
                        <router.Route path="/:parkcode/article" component={loadLazyModule(() => import(/* webpackChunkName: "article" */ "@reco-w/ld-article-article"))} />
                        <router.Route path="/:parkcode/my" component={loadLazyModule(() => import(/* webpackChunkName: "my" */ "@reco-w/ld-my-my"))} />
                        <router.Route path="/:parkcode/workorder" component={loadLazyModule(() => import(/* webpackChunkName: "workorder" */ "@reco-w/ld-workorder-workorder"))} />
                        <router.Route path="/:parkcode/market" component={loadLazyModule(() => import(/* webpackChunkName: "market" */ "@reco-w/ld-market-market"))} />
                        <router.Route path="/:parkcode/product" component={loadLazyModule(() => import(/* webpackChunkName: "market-product" */ "@reco-w/ld-market-product"))} />
                        <router.Route path="/:parkcode/share" component={loadLazyModule(() => import(/* webpackChunkName: "share" */ "@reco-w/share-share"))} />
                        <router.Route path="/:parkcode/msgreach" component={loadLazyModule(() => import(/* webpackChunkName: "msgreach" */ "@reco-w/msgreach-msgreach"))} />
                        <router.Redirect exact to={`/${getParkCode()}`} />
                    </router.Switch>
                </router.Router>
            </App>
        </ConfigProvider>
    );
}

export default RouterConfig;
