import { ReducersMapObject } from "redux";
import { EffectsMapObject } from "dva";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { collectionHttpService } from "@reco-w/favorites-service";

import { Namespaces } from "./common";

export namespace favoritesLinkModel {
    export const namespace = Namespaces.favoritesButton;

    export type StateType = typeof state;

    export const state: any = freeze(
        {
            ...CoreState,
            isFollow: false,
        },
        !0
    );

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 判断是否收藏
         */
        *isFollow({ bindTableName, bindTableId, error }, { call, put }) {
            try {
                const result = yield call(collectionHttpService.isCollected, {
                    bindTableName,
                    bindTableId,
                });
                yield put({ type: "input", data: { isFollow: result } });
            } catch (e) {
                yield call(error, "" + e.errmsg);
            }
        },
        /**
         * 收藏/取消收藏
         */
        *judgeFollowType({ followFlag, bindTableName, bindTableId, bindTableValue, unFollowCall, followCall, extraParams }, { put }) {
            try {
                if (followFlag)
                    yield put({
                        type: `unFollow`,
                        bindTableName: bindTableName,
                        bindTableId: bindTableId,
                        callsuccess: unFollowCall,
                    });
                if (!followFlag)
                    yield put({
                        type: `followActivity`,
                        bindTableName: bindTableName,
                        bindTableId: bindTableId,
                        bindTableValue: bindTableValue,
                        extraParams,
                        callsuccess: followCall,
                    });
            } catch (e) {
                // yield call(error, e);
            }
        },

        /**
         * 取消收藏
         */
        *unFollow({ bindTableName, bindTableId, callsuccess }, { call, put }) {
            try {
                const result = yield call(collectionHttpService.cancelCollection, {
                    bindTableName,
                    bindTableId,
                });
                if (result) {
                    yield put({ type: "input", data: { isFollow: false } });
                    yield call(callsuccess, "取消收藏成功");
                }
            } catch (e) {
                // yield call(error, e);
            }
        },
        /**
         * 收藏
         */
        *followActivity({ bindTableName, bindTableId, bindTableValue, callsuccess, extraParams }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const result = yield call(collectionHttpService.post, {
                    bindTableName,
                    bindTableId,
                    bindTableValue,
                    ...extraParams,
                });

                if (result) {
                    yield put({ type: "input", data: { isFollow: true } });
                    yield call(callsuccess, "收藏成功");
                }
            } catch (e) {
                // yield call(error, e);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(favoritesLinkModel);
