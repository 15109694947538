import { transformUrl } from "@reco-m/core";

/**
 * 验证图片
 */
 export function isImage(file: any) {
    return /.(png|jpg|gif|jpeg|webp)$/i.test(file);
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise} 
 */
export function getBlob(url) {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response);
            }
        };

        xhr.send();
    });
}
/**
 * 保存
 * @param  {Blob} blob     
 * @param  {String} filename 想要保存的文件名称
 */
export function  saveAs(blob, filename) {
    const link = document.createElement("a");
    const body = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = "none";
    body!.appendChild(link);

    link.click();
    body!.removeChild(link);

    window.URL.revokeObjectURL(link.href);
}
/**
 * 下载文件自定义名称
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
 export function download(url, filename) {
    if (isImage(filename)) {
        window.open(transformUrl(url));

    } else {
        getBlob(url).then(blob => {
            saveAs(blob, filename);
        });
    }

}
