import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, formatDateTime, getCurrentParkId } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { policyDeclareService, policyService } from "@reco-w/policy-service";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";

import { Namespaces, POLICY_TAG_ARR } from "./common";
import { getMultiSelectIDs, getMultiSelectValues } from "./utils";

export namespace policyModel {
    export const namespace = Namespaces.policy;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            checkedTags: [],
            selectedTags: [],
            zcjbUnlimited: true,
            zclxUnlimited: true,
            fchyUnlimited: true,
            open: false,
            applyTags: {},
        },
        !0
    );
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, tagData, callback }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield put({ type: "getTagData", message, tagt: tagData.tagt });
                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/policy", message });

                if (callback) yield call(callback);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 处理Url，获取列表
         */
        *getPolicyListByUrl({ message, props, state, applyTagID }, { call, put }) {
            try {
                const tagUrlMaps = POLICY_TAG_ARR.map((x) => x.urlMap);
                const params = getSearchUrl(
                    [CommonSearchParamInUrlEnum.key, CommonSearchParamInUrlEnum.pageIndex, CommonSearchParamInUrlEnum.pageSize, "idx", ...tagUrlMaps],
                    props
                );

                let selectedTagIDs: any[] = [];
                for (let item of POLICY_TAG_ARR) {
                    const values = getMultiSelectValues(params[item.urlMap]);
                    const ids = getMultiSelectIDs(values, state![item.tagCode]);
                    selectedTagIDs = [...selectedTagIDs, ...ids];
                    yield put({ type: "input", data: { [item.valueMap]: values } });
                }

                yield put({
                    type: "input",
                    data: {
                        key: params[CommonSearchParamInUrlEnum.key],
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 10,
                        policyTypeUrl: params["pt"] || null,
                        policyRankUrl: params["pr"] || null,
                        supportIndustryUrl: params["si"] || null,
                        selectedTagIDs,
                        idx: params["idx"] || "0",
                    },
                });

                const data = {
                    key: params[CommonSearchParamInUrlEnum.key],
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 10,
                    applyTagID: params["idx"] === "1" ? applyTagID : selectedTagIDs,
                    isValid: true,
                    idx: params["idx"] || "0",
                    orderBy: "isTop desc,sequence,id desc",
                };

                yield put({ type: "getPolicy", data: data });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 获取搜索的政策列表
         */
        *getSearchPolicyList({ message, params, searchType, pageIndex, pageSize, key, callback }, { call, put }) {
            try {
                if (
                    params["st"] &&
                    Number(params["st"]) === searchType &&
                    (pageIndex !== params[CommonSearchParamInUrlEnum.pageIndex] || pageSize !== params[CommonSearchParamInUrlEnum.pageSize])
                ) {
                    callback && callback(params[CommonSearchParamInUrlEnum.pageIndex], params[CommonSearchParamInUrlEnum.pageSize]);

                    yield put({
                        type: "input",
                        data: {
                            currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                            pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 10,
                            globalKey: params[CommonSearchParamInUrlEnum.globalKey],
                        },
                    });

                    const data = {
                        key,
                        parkId: getCurrentParkId(),
                        pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                        pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 10,
                        isValid: true,
                    };

                    yield put({
                        type: "getPolicy",
                        data: data,
                    });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 获取政策列表
         * @param { message, data }
         * @param { call, put }
         */
        *getPolicy({ message, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const result = yield call(data.idx === "2" ? policyDeclareService.getPolicyPaged : policyService.getPaged, data);

                if (result.items) {
                    let formatDate = result.items.map((item, index) => {
                        return {
                            key: index + 1,
                            number: index + 1,
                            title: item.title,
                            date: formatDateTime(item.publishTime, "yyyy-MM-dd"),
                            id: item.id,
                            declareStartTime: item.declareStartTime,
                            declareId: item.declareId,
                            declareEndTime: item.declareEndTime,
                            applyTags: item.applyTags,
                        };
                    });
                    yield put({ type: "input", data: { policyData: formatDate, totalItems: result.totalItems && Number(result.totalItems) } });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取标签数据
         * @param { message, tagt }
         * @param { call, put }
         */
        *getTagData({ message, tagt }, { call, put }) {
            try {                
                const tagter = yield call(tagService.getTagByTagClasses, { ...tagt, parkId: getCurrentParkId() });
                yield put({ type: "input", data: { ...tagter } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 获取标签数据
         * @param { message }
         * @param { call, put }
         */
        *getApplyTags({ message, callback }, { call, put }) {
            try {
                const applyTags = yield call(policyService.getApplyTags, { applyTagFlags: [1, 2, 3] });
                yield put({ type: "input", data: { applyTags } });

                callback && callback(applyTags);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}
app.model(policyModel);
