import { transformAssetsUrl, resolveEnvConfigProp } from '@reco-m/core';

const ASSETS_HOME_REG = /\bassets\.bitech\.cn\b/i,
    defaultAssetsHomeUrl = ['//assets', 'bitech', 'cn/'].join('.'),
    defaultAssetsBetaHomeUrl = `${defaultAssetsHomeUrl}beta/`,
    assetsHomeUrl = getAssetsHomeUrl(),
    isDefaultAssetsHome = ASSETS_HOME_REG.test(assetsHomeUrl);

export function getAssetsHomeUrl(): string {
    return resolveEnvConfigProp('assetsHomeUrl') ?? window['ASSETS_HOME_URL'] ?? window.parent['ASSETS_HOME_URL'] ?? '//liandugqfw.com/';
}

export function resolveAssetsHomeUrl(url: string): string {
    if (url?.charAt(0) === '~') {
        const assetsUrl = getAssetsHomeUrl();

        return assetsUrl === void 0 ? transformAssetsUrl(url)! : `${assetsUrl}${url.substring(2)}`;
    }

    return url;
}


export function isDefaultAssetsHomeUrl() {
    return isDefaultAssetsHome;
}

export function isAssetsHomeUrl(url: string) {
    return ASSETS_HOME_REG.test(url);
}

export function toggleDefaultAssetsHomeUrl(url: string) {
    return isDefaultAssetsHomeUrl() ? url : `${url}`.replace(defaultAssetsBetaHomeUrl, assetsHomeUrl).replace(defaultAssetsHomeUrl, assetsHomeUrl);
}
