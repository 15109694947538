import { EffectsMapObject, SubscriptionsMapObject, router } from 'dva';
import { ReducersMapObject } from 'redux';
import { freeze } from 'immer';

import { CoreEffects, CoreReducers, pictureService, customEvent, getObjectProp } from '@reco-m/core';
import { app, mtaH5Click, statisticsEvent, systemTokenPromise } from '@reco-w/core-ui';

import { authService, personInfoService } from '@reco-w/auth-service';
import { IParkBindTableNameEnum } from '@reco-w/common-common';

import { Namespaces } from './common';

const logoutRouter = getObjectProp(client, 'logoutRouter', '/login'),
    logoutJumpLogic: any = getObjectProp(client, 'logoutJumpLogic', () => true);

export namespace authUserModel {
    export const namespace = Namespaces.user;
    export const state: any = freeze({}, !0);
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 获取用户头像
         */
        *getAvatar({ isRefresh }, { call, put }) {
            try {
                let user;
                if (isRefresh) {
                    user = yield call(authService.refreshCurrentUser);
                } else {
                    user = yield call(authService.getCurrentUser);
                }
                const currentUser = user && user!.currentUser;
                if (currentUser && currentUser.id) {
                    const pictureSrc = yield call(pictureService.getPictureList, {
                        bindTableName: IParkBindTableNameEnum.account,
                        bindTableId: currentUser.id,
                        customType: 1,
                    });
                    yield put({ type: 'input', data: { avatar: pictureSrc?.length ? pictureSrc[pictureSrc.length - 1] : '' } });
                } else {
                    yield put({ type: 'input', data: { avatar: '' } });
                }
            } catch (error) {
                return Promise.reject(error);
            }
        },
        /**
         * 获取用户信息
         */
        *getUserInfo({}, { call, put }) {
            try {
                const user = yield call(authService.getCurrentUser);
                const currentUser = user && user!.currentUser;
                const userInfo = yield call(personInfoService.getInfo, {
                    bindTableName: IParkBindTableNameEnum.account,
                    bindTableId: currentUser.id,
                });
                yield put({ type: 'input', data: { userInfo } });
            } catch (error) {
                error && error!(error);
            }
        },

        *cleanCurrentUser({}, {}) {
            authService.clearCurrentUser();
        },

        *getCurrentUser({ error, callback }, { call, put }) {
            try {
                const user = yield call(authService.getCurrentUser);

                yield put({ type: 'input', data: user });

                if (callback) yield call(callback, user);
            } catch (e) {
                error && error!(e);
            }
        },

        *refreshCurrentUser({ error, callback }, { call, put }) {
            try {
                const user = yield call(authService.refreshCurrentUser);

                yield put({ type: 'input', data: user });

                if (callback) yield call(callback, user);
            } catch (e) {
                error && error!(e);
            }
        },

        *getCurrentUserFullInfo({ error, callback, ...props }, { put, call }) {
            try {
                yield put({ ...props, error, type: 'getAvatar' });

                yield put({
                    ...props,
                    error,
                    type: 'getCurrentUser',
                    *callback() {
                        callback && (yield call(callback));
                    },
                });
            } catch (e) {
                error!(e);
            }
        },

        *logout({ error, success }, { call, put }) {
            try {
                yield call(authService.logout);

                yield put({ type: 'initState' });
                yield put({ type: 'initState' });

                customEvent.emit('logout');

                mtaH5Click(statisticsEvent.logout);

                if (success) yield call(success);
            } catch (e) {
                if (error) yield call(error, e);
            }
        },
    };

    export const subscriptions: SubscriptionsMapObject = {
        setup({ history, dispatch }) {
            if (logoutRouter) {
                customEvent.on('voucherInvalid', (error) => {
                    systemTokenPromise.then(() => {
                        if (logoutJumpLogic({ history, dispatch, error })) {
                            dispatch({ type: 'logout' });

                            if (!router.matchPath(history.location.pathname, logoutRouter)) {
                                history.push(logoutRouter);
                            }
                        }
                    });
                });
            }
        },
    };
}

app.model(authUserModel);
