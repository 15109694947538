import React from "react";
import { UploadProps } from "antd/lib/upload";

import {BasePictureDetail} from "@reco-m/core";

import { getPictureDefaultProps } from "./util";
import { UploadWrap } from "./upload.wrap";


export type PictureDetailProps = Omit<BasePictureDetail.IProps, "children"> & UploadProps;

export namespace PictureDetail {
    export interface IProps extends PictureDetailProps {}

    export interface IState extends BasePictureDetail.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BasePictureDetail.Component<P, S> {
        static displayName = "PictureDetail";
        static defaultProps = getPictureDefaultProps();

        render(): React.ReactNode {
            let tprops = {
                readonly: true,
                picture: true
            } as any;
            return <UploadWrap.Component {...this.props} {...tprops}/>;
        }
    }
}
