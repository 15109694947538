import { getLocalToken, refreshSystemToken, HttpClient, customEvent, logout, getApiUrl } from '@reco-m/core';

export const systemTokenPromise = (HttpClient.systemTokenPromise = new Promise<void>((resolve) => {
    const ssoId = `${Math.abs(hashCode(`${getApiUrl() || document.baseURI}`))}`;

    addEventListener('storage', (e) => {
        if (e.key === `sso::get::token::${ssoId}` || e.key === `sso::logged::${ssoId}`) {
            const token = getLocalToken(),
                now = `${e.newValue}`;

            if (token && now.startsWith('now:')) {
                localStorage.setItem(`sso::get::token::${ssoId}`, token);
            } else if (now.startsWith('Bearer ')) {
                refreshSystemToken({ token_str: now, access_token: '', refresh_token: '' }, false);

                localStorage.setItem(`sso::get::token::${ssoId}`, `reset:${Date.now()}`);

                location.reload();
            }
        } else if (e.key === `sso::logout::${ssoId}`) {
            logout();

            location.reload();
        }
    });

    customEvent.on('logout', () => {
        localStorage.setItem(`sso::logout::${ssoId}`, `now:${Date.now()}`);
    });

    customEvent.on('logged', () => {
        localStorage.setItem(`sso::logged::${ssoId}`, getLocalToken()!);
    });

    if (!getLocalToken()) {
        localStorage.setItem(`sso::get::token::${ssoId}`, `now:${Date.now()}`);

        setTimeout(resolve, 90);
    } else {
        resolve();
    }
}));

function hashCode(value: string) {
    let hash = 0;

    if (value && value.length) {
        for (let i = 0; i < value.length; i++) {
            let chr = value.charCodeAt(i);

            hash = (hash << 5) - hash + chr;

            hash |= 0;
        }
    }

    return hash;
}
