import React from "react";
import PropTypes from "prop-types";

import { isEqual } from "lodash";

import { requireEnsure, getObjectProp, pictureService, getApiUrl, getAssetsUrl, transformAssetsUrl, getCurrentToken } from "@reco-m/core";

import { getAssetsHomeUrl } from "../util";

const ASSETS_HOME_URL = getAssetsHomeUrl(),
    { readOnlyMode, width, height, style, skin, isRelativePath, assets } = {
        readOnlyMode: "1",
        width: "100%",
        height: 320,
        style: "mini500", // "popup",
        skin: "flat9",
        isRelativePath: false,
        assets: [ASSETS_HOME_URL + "assets/js/ewebeditor/15.0.0/ewebeditor"],
        ...getObjectProp(client, "plugins.eWebEditor"),
    } as any,
    assetsUrl = assets[0] ?? "",
    homeUrl = assetsUrl.substring(0, assetsUrl.lastIndexOf("/") + 1);

export { isRelativePath };

export namespace BiEWEBEditor {
    export interface IProps {
        id?: string;
        data?: string;
        width?: string | number;
        height?: string | number;
        style?: string;
        skin?: string;
        disabled?: boolean;

        ready?: (editor: any) => void;
        change?: (value: string) => void;
    }

    export interface IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends React.Component<P, S> { 
        static displayName = "BiEWEBEditor"; 
        static assetsPromise: Promise<any>;
        static propTypes = {
            data: PropTypes.string,
            editorType: PropTypes.string,
        };

        static defaultProps = {
            width,
            height,
            style,
            skin,
        };

        protected assetsPromise: Promise<any>;

        protected id: string;
        protected editor: any;
        protected editorPromise: Promise<any>;

        protected token: string;
        protected domContainer = React.createRef<any>();
        protected delayHandle: any;
        protected resolveBaseUrlRegExp: RegExp;

        protected content: string;

        constructor(props: P, context: any) {
            super(props, context);

            this.id = this.id = props.id || pictureService.guid("ck");
            this.token = getCurrentToken()!;
            this.resolveBaseUrlRegExp = new RegExp(`(<\\w+[^>]*\\s+\\b(?:src|href)\\b=['"]?)${getAssetsUrl()}([^>]+>)`, "ig");

            window["EWEBEDITOR_BASEPATH"] = `${new URL(homeUrl, location.href)}`;

            this.assetsPromise ??= Component.assetsPromise ??= requireEnsure(assets).then(() => (self || window)["EWEBEDITOR"]);
        }

        componentDidMount() {
            this.initializeEditor();
        }

        componentDidUpdate() {
            this.initializeEditor();
        }

        protected setContent(content: string) {
            const { editor } = this;

            if (editor) {
                editor.setHTML((this.context = content ?? ""));
            }
        }

        protected setReadOnly(disabled: boolean) {
            const { editor } = this;

            if (editor && editor.setReadOnly) {
                editor.setReadOnly(disabled ? readOnlyMode : "");
            }
        }

        shouldComponentUpdate(nextProps: Readonly<P>): boolean {
            const { props, content } = this,
                { data, disabled, ...pProps } = props,
                { data: nData, ...nProps } = nextProps;

            this.setReadOnly(disabled!);

            if (nData !== content) {
                this.setContent(nData!);
            }

            return !isEqual(pProps, nProps);
        }

        componentWillUnmount() {
            const { delayHandle } = this;

            if (delayHandle) {
                clearTimeout(delayHandle);
            }
        }

        render(): React.ReactNode {
            return <div ref={this.domContainer}></div>;
        }

        protected initializeEditor() {
            if (!this.editorPromise) {
                this.editorPromise = new Promise<any>((resolve, reject) => {
                    this.assetsPromise.then((creator) => {
                        const { domContainer, id, props } = this,
                            { current: host } = domContainer,
                            { data, width, height, style, skin, ready, change, disabled } = props,
                            apiBaseUrl = `${getApiUrl()}ewebeditor/`,
                            content = (this.content = data || "");

                        appendChild(`<textarea id="${id}" name="${id}" style="display:none">${content}</textarea>`, host);

                        this.delayHandle = setTimeout(() => {
                            this.delayHandle = null;

                            const editor = (this.editor = creator.Replace(
                                id,
                                {
                                    width,
                                    height,
                                    style,
                                    skin,
                                    systemSkin: "",
                                    apiBaseUrl: `${new URL(apiBaseUrl, location.href)}`,
                                    apiToken: this.token,
                                    resolveUrl: transformAssetsUrl,
                                    onLoadComplete: () => {
                                        this.setReadOnly(disabled!);

                                        resolve(editor);

                                        ready?.(editor);
                                    },
                                    notLicensed: () => {
                                        ready?.(editor);
                                    },
                                    onChange: () => {
                                        let value = editor.getHTML();

                                        if (this.content !== value) {
                                            if (isRelativePath) {
                                                value = value.replace(this.resolveBaseUrlRegExp, "$1~/$2");
                                            }

                                            this.content = value;

                                            change?.(value);
                                        }
                                    },
                                },
                                content
                            ));
                        }, 220);
                    }, reject);
                });
            }
        }
    }
}

export function appendChild(html: string, element: Element) {
    if (element.insertAdjacentHTML) {
        element.insertAdjacentHTML("beforeend", html);
    } else {
        const range = document.createRange();
        range.setStartBefore(element);
        const fragment = range.createContextualFragment(html);
        element.appendChild(fragment);
    }
}
