import { IParkBindTableNameEnum } from "@reco-w/common-common";

export enum Namespaces {
    favorites = "favorites",
    favoritesButton = "favoritesButton",
}

/**
 * 搜索栏参数枚举
 */
export enum SearchParamInUrlEnum {
    /**
     * 选中类型
     */
    chosenFavourite = "mf",
}

export enum FollowTypeEnum {
    /**
     * 收藏
     */
    collect = 1,
    /**
     * 关注
     */
    follow = 2,
    /**
     * 点赞
     */
    pointPraise = 3,
}
/**
 * 收藏tabs
 */
export const FAVORITE_TABS = [
    { tab: "活动", key: IParkBindTableNameEnum.activity },
    { tab: "服务机构", key: IParkBindTableNameEnum.institution },
    { tab: "服务产品", key: IParkBindTableNameEnum.product },
    { tab: "资源", key: IParkBindTableNameEnum.resource },
    { tab: "政策", key: IParkBindTableNameEnum.policy },
];
