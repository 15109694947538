import React from "react";

import {Input} from "antd";
import { TextAreaProps } from "antd/lib/input/TextArea";
import { PureComponent } from "@reco-m/core";

export type TextAreaLengthProps = Omit<PureComponent.IProps, "children"> & TextAreaProps;

export namespace TextAreaLength {
    export interface IProps extends TextAreaLengthProps {
        showLength?: boolean;
        dataSeed?: any;
        rows?: number;
        autoSize?: boolean | object;
    }

    export interface IState extends PureComponent.IState {
        inputLength: any;
    }

    export class Component<P extends TextAreaLength.IProps, S extends TextAreaLength.IState> extends PureComponent.Base<P, S> {
        static displayName = "TextAreaLength";
        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return { inputLength: 0 } as S;
        }

        componentReceiveProps(nextProps: P) {
            if (this.props !== nextProps) {
                let value = nextProps.value && nextProps.value.toString();
                if (value && (value.indexOf("<br/>") > -1 || value.indexOf("<br>") > -1)) value = value.replace(/<br\s*\/?>/gi, "\r\n");
                this.setState({ inputLength: (value && value.length) || 0 });
            }
        }

        render(): React.ReactNode {
            return (
                <div className="textArea-box">
                    <Input.TextArea
                        data-seed={this.props.dataSeed}
                        className={this.props.className}
                        style={this.props.style}
                        placeholder={this.props.placeholder}
                        minLength={this.props.minLength}
                        maxLength={this.props.maxLength}
                        rows={this.props.rows}
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        disabled={this.props.disabled}
                        autoSize={this.props.autoSize}
                        allowClear={this.props.allowClear}
                        readOnly={this.props.readOnly}
                        onChange={this.props.onChange && this.props.onChange.bind(this)}
                        onClick={this.props.onClick && this.props.onClick.bind(this)}
                        onBlur={this.props.onBlur && this.props.onBlur.bind(this)}
                        onFocus={this.props.onFocus && this.props.onFocus.bind(this)}
                        onPressEnter={this.props.onPressEnter && this.props.onPressEnter.bind(this)}
                    />
                    {this.props.showLength && (
                        <div className="txtlimit">
                            <span>{((this.props.maxLength && this.state.inputLength > this.props.maxLength) ? this.props.maxLength : this.state.inputLength) || 0}</span> / {this.props.maxLength || 0}
                        </div>
                    )}
                </div>
            );
        }
    }
}
