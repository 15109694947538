
import React, { useEffect } from "react";
export const PictureTip = (_props: any) => {
    useEffect(() => {
        // 页面进入
        return () => {
            // 页面离开
        };
    }, []);
    return (
        client.pictureTip || <div>
            建议上传尺寸
            <span className="color-red">360*192</span>，大小
            <span className="color-red">500k</span>，JPG、PNG格式的
            <span className="color-red">直角图片</span>
        </div>
    );
};


export function registerPictureTip(pictureTip) {
    client.pictureTip = pictureTip;
}
