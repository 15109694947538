export const client: RECO.Mobile.Config.Client = (window["client"] = {
    title: "BiPark智慧园区应用集成平台",
    techSupport: "技术支持:  上海瑞谷拜特软件技术有限公司",
    urlArgs: "v=180427",
    tel: "021-20231080",
    /**
     * 作为区分同一域名下不同网页的缓存的关键字
     */
    appDomain: "biparkall_web",
    /**
     * 底层退出配置跳转地址
     */
    logoutRouter: `/login`,
    isUseImageVertify: true, // 发送验证码使用图片验证
    app: {
        openBusinessBill: true,
        /** 是否开启行政审批 */
        openAdminiApproval: false,
        /** 是否开启招聘 */
        openRecruit: true,
        /** 是否开启企业主页 */
        openEnterpriseHome: true,
        /** 是否政策申报 */
        openPolicyDeclare: true,
    },
    parkCode: "moryq",
    ...window["client"],
});

export const server: RECO.Mobile.Config.Server = (window["server"] = {
    apiKey: {
        apiKey: "Bitech\\PC",
        secret: "44678314ba0efa0c",
    },
    apiVersion: 9,
    auth: {
        oauth2Url: "authorization/oauth2",
        autoLogin: !0,
        autoRefreshToken: !0,
        anonymousLogin: !0,
    },
    rsa: {
        enable: true,
        publicKey: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXu1ms9TrhrMlIn3iv4g2Pw9Mfr9abdAB-TpDD65G98wjHZxgkZfhzUHE8BSsUDzIHfOiCd4RT5fZtTiop9bzwecgYCrAICjrVC-8ZWKf1RqRX4EXiISyEBHb4YAER_Rt2TQEWGPCi14ujDTA9qr1_YRUFfh52nrc-MfkfC12BwQIDAQAB`,
        privateKey: `MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBANe7Waz1OuGsyUifeK_iDY_D0x-v1pt0AH5OkMPrkb3zCMdnGCRl-HNQcTwFKxQPMgd86IJ3hFPl9m1OKin1vPB5yBgKsAgKOtUL7xlYp_VGpFfgReIhLIQEdvhgARH9G3ZNARYY8KLXi6MNMD2qvX9hFQV-Hnaetz4x-R8LXYHBAgMBAAECgYEAk6DxzRpEIQtM8xWuUS4weaFYaOa7xcWsUMMS7f_WzFX0PACg1MezLbZuxtQClsePRPCFbEK9m2xTJpnG4M_5apVIIuA5QhGPR5kimQMf1jeVYuiXJ2fOOyBza4ydfgRYCi1BhbakvDA5D0NDAT9HdV6Jfmv78QFF4THyKHGe5MECQQDrK88We3MbyZuLwkmEJlSPnP4AS8omCeamB5Gt_kPeRF2fqK0Bi8pfGqHtp8gPu1KQ7Nag7BJvOFDnL1UvlIH_AkEA6tbICd9yzaWYnoesc9P0bEEDH02R6dduzDkVC6Vni6v_KmbS5aZ_B4bV2nldNQiiDCrwulc2_iNO4Ug45SZ8PwJBAJQ-KWyipQz_KW4QKweCogtlizfkjqkr1JkUbGChx2Z08g-S5Hv1ArywrSKCgTppesKgiSsnpPc3KMiSrcAwfUECQAKiPAYYUnJJ_CczAFUgOGqKZB2SwiMsPlrd_QVvanTfjfIMyyq2LlCIS5eyqr_wg9i9FowQdFZvUPAn3jN7tgMCQHeAJZuIKpgCV2mWMQw0FgSf2_UmTjboUqbFf5d11KAW7iMj0ygHwt1bjALthKv7-ixdaAuY0URqZXYNMiAZT1o`,
    },
    webappurl: "https://ys.bitech.cn/reco-biparkall-10-3-webapp/", // 后台地址
    appdownURL: "https://ys.bitech.cn/appdownloadservice/appdown/#/home", // app下载地址

    assetsUrl: "https://liandugqfw.com/liandujsjpark-mobileapi/", // 正在使用
    url: "https://liandugqfw.com/liandujsjpark-mobileapi/",

    ...window["server"],
});
