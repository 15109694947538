import { EffectsMapObject, routerRedux } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app, changeUrlParams, getSearchUrl } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { policyCalculatorService } from "@reco-w/policy-service";
import { Namespaces, PolicyMatchTabIndexEnum } from "./common";

export namespace newPolicyMatchModel {
    export const namespace = Namespaces.newpolicyMatch;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getCertify", message });
                yield put({ type: "getDataByUrl", props, message });
                yield put({ type: "getCalculator", props, message });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put, select }) {
            try {
                const params = getSearchUrl([CommonSearchParamInUrlEnum.childTabIndex], props);

                const state = yield select((state) => state[Namespaces.newpolicyMatch]),
                    certifyDetail = state!.certifyDetail;

                if (
                    certifyDetail?.status !== CertifyStatusEnum.allow &&
                    params[CommonSearchParamInUrlEnum.childTabIndex] &&
                    params[CommonSearchParamInUrlEnum.childTabIndex] !== PolicyMatchTabIndexEnum.materials
                ) {
                    const path = changeUrlParams(window.location.search, { [CommonSearchParamInUrlEnum.childTabIndex]: PolicyMatchTabIndexEnum.materials });
                    yield put(routerRedux.push(path));
                } else if (certifyDetail?.status === CertifyStatusEnum.allow && params[CommonSearchParamInUrlEnum.childTabIndex] === PolicyMatchTabIndexEnum.materials) {
                    const path = changeUrlParams(window.location.search, { [CommonSearchParamInUrlEnum.childTabIndex]: PolicyMatchTabIndexEnum.auto });
                    yield put(routerRedux.push(path));
                } else {
                    const defaultTabIndex = certifyDetail?.status !== CertifyStatusEnum.allow ? PolicyMatchTabIndexEnum.materials : PolicyMatchTabIndexEnum.auto;
                    yield put({ type: "input", data: { stepIndex: params[CommonSearchParamInUrlEnum.childTabIndex] || defaultTabIndex } });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取会员认证信息
         * @param { message }
         * @param { call, put, select }
         */
        *getCertify({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                yield put({ type: "input", data: { certifyDetail, isCertifyAllow: certifyDetail?.status === CertifyStatusEnum.allow } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取企业政策计算器结果
         * @param { message }
         * @param { call, put, select }
         */
        *getCalculator({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.newpolicyMatch]),
                    certifyDetail = state!.certifyDetail;

                if (certifyDetail?.status === CertifyStatusEnum.allow) {
                    const result = yield call(policyCalculatorService.policyMatchPredictResult, {
                        mobile: certifyDetail?.mobile,
                        customerId: certifyDetail?.companyId,
                        parkId: getCurrentParkId(),
                    });

                    const integrity = ((result?.enterpriseIntegrity || 0) * 100).toFixed(2);

                    yield put({ type: "input", data: { customerCalculator: result, integrity } });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(newPolicyMatchModel);
