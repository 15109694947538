import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { policyCalculatorService } from "@reco-w/policy-service";
import { Namespaces, POLICY_TYPE_TAG_CODE } from "./common";

export namespace newPolicyMatchListModel {
    export const namespace = Namespaces.newpolicyMatchList;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getDeclareMode", message });
                yield yield put({ type: "getCertify", message });
                yield yield put({ type: "getPolicyTypeTag", props, message });
                yield put({ type: "getDataByUrl", props, message });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取申报类型
         * @param { message }
         * @param { call, put }
         */
        *getDeclareMode({ message }, { call, put, select }) {
            try {
                let state = yield select((state) => state[Namespaces.newpolicy]),
                    declareMode = state!.declareMode;

                if (!declareMode) {
                    yield yield put({ type: `${Namespaces.newpolicy}/getDeclareMode`, message });
                    state = yield select((state) => state[Namespaces.newpolicy]);
                    declareMode = state!.declareMode;
                }

                yield put({ type: "input", data: { declareMode } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getDataByUrl({ message, props }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const params = getSearchUrl([CommonSearchParamInUrlEnum.pageIndex], props);

                let state = yield select((state) => state[Namespaces.newpolicyMatchList]);
                let certifyDetail = state!.certifyDetail;
                if (!certifyDetail) {
                    yield yield put({ type: "getCertify", message });
                    state = yield select((state) => state[Namespaces.newpolicyMatchList]);
                    certifyDetail = state!.certifyDetail;
                }

                const data = {
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: 99999,
                    parkId: getCurrentParkId(),
                    customerId: certifyDetail?.companyId,
                    isCalculate: props.isCalculator,
                };

                yield put({ type: "input", data: { currentPage: params[CommonSearchParamInUrlEnum.pageIndex] || 1 } });

                yield put({ type: "getMatchImplementationDetailList", props, data, message });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取会员认证信息
         * @param { message }
         * @param { call, put, select }
         */
        *getCertify({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                
                yield put({ type: "input", data: { certifyDetail } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取政策类型
         * @param { message }
         * @param { call, put }
         */
        *getPolicyTypeTag({ message }, { call, put }) {
            
            try {
                const result = yield call(tagService.getTagByTagClass, { tagClass: POLICY_TYPE_TAG_CODE });

                yield put({ type: "input", data: { policyTypes: result } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取企业政策计算器结果
         * @param { message }
         * @param { call, put, select }
         */
        *getMatchImplementationDetailList({ message, data }, { call, put, select }) {
            try {
                const liststate = yield select((state) => state[Namespaces.newpolicyMatchList]);
                let policyTypes = liststate!.policyTypes;

                if (!data?.customerId) {
                    yield put({ type: "hideLoading" });
                    return;
                }

                const result = yield call(policyCalculatorService.getMatchImplementationDetailList, {
                    parkId: getCurrentParkId(),
                    ...data,
                });

                policyTypes = policyTypes.map((item) => ({ ...item, matchList: result?.items?.filter((x) => x.policyTypeValue === item.tagValue) }));

                policyTypes = policyTypes?.filter((x) => x.matchList?.length);

                yield put({ type: "input", data: { ...result, panels: policyTypes } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(newPolicyMatchListModel);
