import React from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { BaseAttachUpload } from "@reco-m/core";

export namespace AttachUpload {
    export interface IProps extends BaseAttachUpload.IProps {}

    export interface IState extends BaseAttachUpload.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BaseAttachUpload.Component<P, S> { 
        static displayName = "AttachUpload"; 
        renderBody(): React.ReactNode {
            const { content } = this.props as any;

            return (
                <span>
                    <Button>
                        <UploadOutlined type="upload" /> {content}
                    </Button>
                </span>
            );
        }
    }
}
