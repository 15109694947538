import { freeze } from "immer";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { CoreEffects, CoreReducers, CoreState, getCurrentParkId } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { newCouponHttpService } from "@reco-w/coupon-service";

import { CouponStatusEnum, CouponTicketStatusEnum, Namespaces } from "./common";

export namespace couponchiceModel {
    export const namespace = Namespaces.couponChoice;
    export const state: any = freeze({
        ...CoreState,
        selectedCoupons: [],
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ data, message }, { put, call }) {
            put({ type: "showLoading" });

            try {
                yield put({
                    type: `getMemberOrderUseCoupon`,
                    sceneValue: data.sceneValue,
                    orderAmount: data.orderAmount,
                    message,
                });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取可用优惠券
         * @param { sceneValue, orderAmount, message }
         * @param { call, put }
         */
        *getMemberOrderUseCoupon({ sceneValue, orderAmount, message }, { call, put }) {
            try {
                const result = yield call(newCouponHttpService.getMyCoupon, {
                    parkId: getCurrentParkId(),
                    couponStatus: CouponStatusEnum.canUse,
                    sceneValueList: sceneValue,
                    pageSize: 500,
                    status: CouponTicketStatusEnum.canUse,
                }) || {};
                let items = result.items;

                let couponData: any = [];
                if (items && items.length > 0) {
                    items.forEach((t) => {
                        if ((!t.minUsefulAmount || t.minUsefulAmount <= orderAmount) && t.denomination <= orderAmount) {
                            couponData.push(t);
                        }
                    });
                }
                yield put({ type: "input", data: { couponData: couponData } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}
app.model(couponchiceModel);
