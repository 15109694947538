import React from "react";

import { isString } from "lodash";
import dayjs from "dayjs";

import { DatePicker, DatePickerProps } from "antd";
import moment, { Moment } from "moment/moment";

import { BiPickerComponent } from "./bi.picker";

export namespace BiDatePicker {
    export interface IProps extends BiPickerComponent.IProps {
        showTime?: any;
        format?: string;
    }

    export interface IState extends BiPickerComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BiPickerComponent.Base<P & DatePickerProps, S> {
        /***
         * 限制最大日期最小日期
         ***/
        protected disabledDate(date: Moment) {
            let min: any = this.props.minDate,
                max: any = this.props.maxDate;

            min && (min = moment(min));
            max && (max = moment(max));

            const d = moment(date).startOf("day");
            const m = moment(date).endOf("day");

            return !date || (max && d.isAfter(max)) || (min && m.isBefore(min));
        }

        /***
         * 判断格式化是否带时间，开启时间选择，通过空格判断后面的时分秒
         ***/
        isShowTime() {
            const f = this.props.format;
            return f?.split(" ").length === 2 ? { format: f?.split(" ")[1] } : false;
        }

        render(): React.ReactNode {
            const { format = "yyyy-MM-dd", value } = this.props;

            return (
                <DatePicker
                    showTime={this.isShowTime()}
                    disabledDate={this.disabledDate.bind(this)}
                    disabledTime={this.props?.showTime || this.isShowTime() ? this.disabledDateTime.bind(this) : null}
                    {...this.props}
                    value={value && isString(value) && dayjs(value, format)}
                />
            );
        }
    }
}
