import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberService } from "@reco-w/member-service";
import { newCouponHttpService } from "@reco-w/coupon-service";

import { Namespaces } from "./common";

export namespace mycouponModel {
    export const namespace = Namespaces.myCoupon;
    export const state: any = freeze({
        ...CoreState,
        showloading: true,
        couponState: "1",
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getstaffmanagerList", message });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 获取我的优惠券
         * @param { error, data }
         * @param { call, put }
         */
        *mycouponGetListPage({ error, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let couponList = yield call(newCouponHttpService.getMyCoupon, data);

                yield put({ type: "input", data: { couponList: couponList.items, total: couponList.totalItems && Number(couponList.totalItems) } });
            } catch (e) {
                yield call(error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取员工列表
         */
        *getstaffmanagerList({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user,
                    // certifyDetail = memberCommonState.get("certifyDetail"),
                    member = memberCommonState.member;
                yield put({ type: "input", data: { myMember: member } });

                if (member?.status === CertifyStatusEnum.allow) {
                    let cmpanyid = member && member.companyId;
                    let params = {
                        pageIndex: 1,
                        pageSize: 1000,
                        companyId: cmpanyid,
                        status: CertifyStatusEnum.allow,
                        isValid: true,
                    };
                    const data = yield call(memberService.getCompanyStaffList, params);
                    let certifyMember: any = [],
                        certigyMemberName: any = [],
                        certifyMemberArr: any = [];
                    if (data) {
                        data.items.forEach((item, i) => {
                            if (user && user.currentUser && item.accountId !== user.currentUser.id) {
                                let dic = {};
                                dic["label"] = item.realName;
                                dic["value"] = item.accountId;
                                if (i === 0) {
                                    certigyMemberName.push(item.accountId);
                                }
                                certifyMemberArr.push(dic);
                            }
                        });
                    }
                    certifyMember.push(certifyMemberArr);
                    console.log(certifyMember, data.items);
                    yield put({ type: "input", data: { certifyMember, certigyMemberName } });
                }
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
        /**
         * 转赠优惠券
         */
        *givenCoupon({ message, data, id, successCall }, { call }) {
            try {
                yield call(newCouponHttpService.givenCoupon, id, data);
                successCall();
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },
    };
}
app.model(mycouponModel);
