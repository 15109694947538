import { EffectsCommandMap, EffectsMapObject } from 'dva';
import { AnyAction, ReducersMapObject } from 'redux';

import { getLocalStorage } from '@reco-m/core';

import { stateFlowService } from '@reco-w/approval-service';
import { BiFormData, BiFormMetaData } from '@reco-w/form-engine';

export const ApprovalStateBaseReducers: ReducersMapObject<any, AnyAction> = {};

export const ApprovalStateBaseEffects: EffectsMapObject = {
    *getFormData({ payload }: AnyAction, { put }: EffectsCommandMap) {
        const formData = yield yield put({ type: 'getWorkFlowForm', payload }),
            variables = yield yield put({ type: 'getStateVariables', payload: formData }),
            routes = yield yield put({ type: 'getStateRoutes', payload: { ...payload, ...formData, variables } });

        return { ...formData, variables, routes };
    },
    *getWorkFlowForm({ payload }: AnyAction, { call }: EffectsCommandMap) {
        const { flowProjectId, routeId } = payload,
            { getState, createState } = stateFlowService,
            formMetaData: BiFormMetaData = yield call(flowProjectId ? getState : createState, payload),
            { flow, project, projectContent } = formMetaData,
            formData: BiFormData = JSON.parse(projectContent.componentData!),
            incomeParams = { ...formData.urlData, ...payload };

        formData.urlData = incomeParams;

        const node = flow.process?.nodes?.find(({ nodeId }) => nodeId === project.currentNodeId),
            permissions = routeId ? flow.process?.routes?.find((r: any) => r.routeId === routeId)?.permissions || [] : node?.permissions || [];

        return {
            flowProjectId: project.id,
            formMetaData,
            formData,
            incomeParams,
            permissions,
        };
    },
    *getStateVariables({ payload }: AnyAction, { call }: EffectsCommandMap) {
        const { project, task } = payload.formMetaData,
            variables: any[] = yield call(stateFlowService.getStateVariables, { flowProjectId: project.id, taskId: task.id });

        setValue('系统变量.当前公司名称', 'companyName');
        setValue('系统变量.当前公司ID', 'companyId');

        return variables;

        function setValue(key: string, cacheKey: string) {
            const v = variables.find(({ text }) => text === key);

            if (v) {
                v.value = getLocalStorage(cacheKey);
            }
        }
    },
    *getStateRoutes({ payload, data }: AnyAction, { call }: EffectsCommandMap) {
        const { flowProjectId, taskId, formMetaData } = payload || data,
            { flow, project, projectContent } = formMetaData as BiFormMetaData;

        let routes = flow.process?.routes?.filter(({ fromNodeId }) => fromNodeId === project.currentNodeId) || [];

        if (flowProjectId && routes.some(({ conditions }) => conditions?.length)) {
            routes = yield call(stateFlowService.getStateRoutes, {
                flowProjectId,
                componentData: projectContent?.componentData,
                taskId,
            });
        }

        return (routes = (routes || []).filter(({ isValid }) => isValid));
    },
    *transitState({ payload, data }: AnyAction, { call }: EffectsCommandMap) {
        return yield call(stateFlowService.transitState, payload || data);
    },
};
