import React from "react";

import { template } from "@reco-m/core";
import { ViewComponent, HtmlContent, getParkCode } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";
import { UserAgreementModel } from "@reco-w/layout-blocks";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {}
    export interface IState extends ViewComponent.IState, footerModel.StateType {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;
        /**
         * 计时器
         */
        timer;

        componentDidMount() {
            this.timer && clearTimeout(this.timer);
            this.dispatch({
                type: "initPage",
                callback: (appConfig) => {
                    if (appConfig?.footerHtml?.indexOf("onclickUserAgreement()") > -1) {
                        this.setTimer();
                    }
                },
            });
        }

        componentWillUnmount(): void {
            this.timer && clearTimeout(this.timer);
        }

        /**
         * 设置计时器
         */
        setTimer() {
            this.timer = setInterval(() => {
                if (window && window["isClickUserAgreement"]) {
                    this.setModal2Visible(true, 0);
                    window["isClickUserAgreementNew"] = false;
                    window["isClickUserAgreement"] = false;
                } else if (window && window["isClickUserAgreementNew"]) {
                    this.setModal2Visible(true, 1);
                    window["isClickUserAgreementNew"] = false;
                    window["isClickUserAgreement"] = false;
                }
            }, 500);
        }

        setModal2Visible(isUserAgreement, agreementType?) {
            this.dispatch({ type: "input", data: { isUserAgreement: isUserAgreement, agreementType } });
        }

        renderUserAgreementModel(): React.ReactNode {
            const { state } = this.props,
                agreementType = state!.agreementType,
                isUserAgreement = state!.isUserAgreement;
            console.log("isUserAgreement", isUserAgreement);
            const prop =
                agreementType === 1
                    ? {
                          title: "隐私政策",
                          configMap: "privacyPolicy",
                      }
                    : {};
            return this.renderEmbeddedView(UserAgreementModel.Page as any, {
                isVisible: isUserAgreement,
                onClose: () => this.setModal2Visible(false),
                ...prop,
            });
        }

        renderFooter(): React.ReactNode {
            const { state } = this.props,
                appConfig = state!.appConfig,
                webPath = (location.origin + location.pathname || "").replace(this.props.location?.pathname || "", "") + "/",
                parkCode = getParkCode(),
                html = (appConfig?.footerHtml || "").replace(/{webPath}/g, webPath).replace(/{parkCode}/g, parkCode);

            return <HtmlContent.Component html={html}></HtmlContent.Component>;
        }

        render() {
            return (
                <div className="footer">
                    {this.renderFooter()}
                    {this.renderUserAgreementModel()}
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
