import { HttpService, resolveService } from '@reco-m/core';

export class FlowApiHttpService extends HttpService {
    constructor(controlName) {
        super(controlName);
    }

    transformUrl(url?: string): string {
        return this.controllerName ? super.transformUrl(url) : url!;
    }

    getCustom(url: string, data?: any) {
        return this.httpGet(url, data ? this.resolveRequestParams(data) : {});
    }
}

export const flowApiService = resolveService(FlowApiHttpService);
