import { mtaH5Click, statisticsEvent, getParkCode } from "@reco-w/core-ui";
import { CouponStatusEnum, SceneCodeEnum } from "./common";
/**
 * 获取状态操作按钮
 * @param state
 * @returns
 */
export function getTextOfButton(state) {
    if (state === CouponStatusEnum.notUse) {
        return "立即使用";
    } else if (state === CouponStatusEnum.isUse) {
        return "已使用";
    } else if (state === CouponStatusEnum.isExpire) {
        return "已过期";
    } else if (state === CouponStatusEnum.isStop) {
        return "已停用";
    } else if (state === CouponStatusEnum.isGiven) {
        return "已转赠";
    }
}

/**
 * 立即使用
 * @param item
 * @returns
 */
export function useCoupon(item) {
    mtaH5Click(statisticsEvent.myCouponUse);
    if (item.sceneValue === SceneCodeEnum.meetingRoom) {
        return `/${getParkCode()}/resource/roommeet`;
    } else if (item.sceneValue === SceneCodeEnum.square) {
        return `/${getParkCode()}/resource/roomarea`;
    } else if (item.sceneValue === SceneCodeEnum.station) {
        return `/${getParkCode()}/resource/positionstation`;
    } else if (item.sceneValue === SceneCodeEnum.advertisement) {
        return `/${getParkCode()}/resource/positionadvert`;
    }
    return `/${getParkCode()}`;
}
