import React from "react";
import PropTypes from "prop-types";

import { isEqual } from "lodash";

import { CKEditor } from "./ckeditor";
import { BiEWEBEditor } from "./ewebeditor";

import { BiEditorTypeEnum } from "./editor-type.enum";
import { getObjectProp } from "@reco-m/core";

const editorType: BiEditorTypeEnum = getObjectProp(client, "plugins.editor.editorType", BiEditorTypeEnum.ckeditor);

export namespace BiEditor {
    export interface IProps {
        id?: string;
        data?: string;
        disabled?: boolean;

        editorType?: BiEditorTypeEnum;

        ready?: (editor: any) => void;
        change?: (value: string) => void;
    }

    export interface IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends React.Component<P, S> { 
        static displayName = "BiEditor"; 
        static propTypes = {
            data: PropTypes.string,
            editorType: PropTypes.string,
        };

        static defaultProps = {
            editorType,
        };

        shouldComponentUpdate(nextProps: Readonly<P>): boolean {
            return !isEqual(this.props, nextProps);
        }

        renderEWEBEditor(props: IProps): React.ReactNode {
            return <BiEWEBEditor.Component {...props} />;
        }

        renderCKEditor(props: IProps): React.ReactNode {
            const { ready, change } = props;

            return <CKEditor.Component {...props} onReady={(editor) => ready?.(editor)} onChange={(_, editor) => change?.(editor.getData())} />;
        }

        render(): React.ReactNode {
            const { editorType, ...props } = this.props;

            return editorType === BiEditorTypeEnum.ewebeditor ? this.renderEWEBEditor(props) : this.renderCKEditor(props);
        }
    }
}
