import {PureComponent} from "@reco-m/core";
import moment from "moment";

export namespace BiPickerComponent {
    export interface IProps extends PureComponent.IProps {
        minDate?: any;
        maxDate?: any;
    }

    export interface IState extends PureComponent.IState {

    }

    export abstract class Base<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        /***
         * 创建时数组
         ***/
        hours = Array.from({ length: 24 }, (_item, i) => {
            return i;
        });

        /***
         * 创建分、秒数组
         ***/
        minutesAndSeconds = Array.from({ length: 60 }, (_item, i) => {
            return i;
        });

        /***
         * 如果最大最小日期是同一天，禁用对应的时间
         ***/
        disabledTimeValue(min: any, max: any, minHours: any[], maxHours: any[]) {
            return {
                disabledHours: () => {
                    return [...minHours, ...maxHours];
                },
                disabledMinutes: (hour: number) => {
                    if (min?.format('HH') === max?.format('HH')) {
                        return this.minutesAndSeconds.filter((d: number) => d < Number(min?.format('mm')) || d > Number(max?.format('mm')));
                    }
                    if (hour <= Number(min?.format('HH'))) {
                        return this.minutesAndSeconds.filter((d: number) => d < Number(min?.format('mm')));
                    }
                    if (hour >= Number(max?.format('HH'))) {
                        return this.minutesAndSeconds.filter((d: number) => d > Number(max?.format('mm')));
                    }

                    return [];
                },
                disabledSeconds: (hour: number, minute: number) => {
                    if (min?.format('HH') === max?.format('HH') && Number(min?.format('mm')) === Number(max?.format('mm'))) {
                        return this.minutesAndSeconds.filter((d: number) => d < Number(min?.format('ss')) || d > Number(max?.format('ss')));
                    }

                    if (hour <= Number(min?.format('HH')) && minute <= Number(min?.format('mm'))) {
                        return this.minutesAndSeconds.filter((d: number) => d < Number(min?.format('ss')));
                    }

                    if (hour >= Number(max?.format('HH')) && minute >= Number(max?.format('mm'))) {
                        return this.minutesAndSeconds.filter((d: number) => d > Number(max?.format('ss')));
                    }
                    return [];
                },
            };
        }

        /***
         *禁用时间
         ***/
        protected disabledDateTime(date: any) {
            let min: any = this.props.minDate,
                max: any = this.props.maxDate;

            min && (min = moment(min));
            max && (max = moment(max));

            const minHours = this.hours.filter((d: number) => d < Number(min?.format('HH')));
            const maxHours = this.hours.filter((d: number) => d > Number(max?.format('HH')));

            //如果最大最小日期是同一天
            if (min?.isSame(max, 'day') && date?.isSame(min, 'day')) {
                return this.disabledTimeValue(min, max, minHours, maxHours);
            }

            // 判断是否选择的是最小日期的当天
            if (date?.isSame(min, 'day')) {
                return {
                    disabledHours: () => {
                        return minHours;
                    },
                    disabledMinutes: (hour: number) => {
                        if (hour <= Number(min?.format('HH'))) {
                            return this.minutesAndSeconds.filter((d: number) => d < Number(min?.format('mm'))).filter((d: number) => d < Number(min?.format('mm')));
                        }
                        return [];
                    },
                    disabledSeconds: (hour: number, minute: number) => {
                        if (hour <= Number(min?.format('HH')) && minute <= Number(min?.format('mm'))) {
                            return this.minutesAndSeconds.filter((d: number) => d < Number(min?.format('ss')));
                        }
                        return [];
                    },
                };
            }
            // 判断是否选择的是最大日期的当天
            if (date?.isSame(max, 'day')) {
                return {
                    disabledHours: () => {
                        return maxHours;
                    },
                    disabledMinutes: (hour: number) => {
                        if (hour >= Number(max?.format('HH'))) {
                            return this.minutesAndSeconds.filter((d: number) => d > Number(max?.format('mm')));
                        }
                        return [];
                    },
                    disabledSeconds: (hour: number, minute: number) => {
                        if (hour >= Number(max?.format('HH')) && minute >= Number(max.format('mm'))) {
                            return this.minutesAndSeconds.filter((d: number) => d > Number(max?.format('ss')));
                        }
                        return [];
                    },
                };
            }

            // 判断是否比最小日期小比最大日期大
            if (date?.isBefore(min) && date?.isAfter(max)) {
                return {
                    disabledHours: () => {
                        return this.hours;
                    },
                    disabledMinutes: () => {
                        return this.minutesAndSeconds;
                    },
                    disabledSeconds: () => {
                        return this.minutesAndSeconds;
                    },
                };
            }

            return {
                disabledHours: () => {
                    return [];
                },
                disabledMinutes: () => {
                    return [];
                },
                disabledSeconds: () => {
                    return [];
                },
            };
        }

    }
}
