import { browser } from './browser';

export function replaceBr(centent) {
    return centent?.replace(/\n/g, '<br />') || '';
}

/*——————————————————————————————适配小程序安卓返回start——————————————————————————————*/
let pathname = null, // 小程序跳转h5初始页面
    thispathname = null, // 小程序h5首页跳转前所在页面
    nextpathname = null; // 小程序h5即将跳转的页面

let compatibled = false;

export function setInitPathname(url) {
    pathname = url && url.split('?')[0];
}

// 兼容微信小程序webview跳转
export function goToCompatibleWxmini(_this, fun, state) {
    return new Promise<void>((resolve) => {
        // fun();

        let _thispathname = _this.props.location?.pathname;
        // 处于小程序中，并且小程序跳转初始页面就是当前页面时才处理兼容个别安卓操作
        if (browser.versions.weChatMini && browser.versions.android && pathname === _thispathname && !client['noCompatibleWxmini']) {
            thispathname = _thispathname;
            _thispathname && _this.__goTo(_this.resolveRoutePath(_thispathname, state), state); // _this.goTo(_thispathname);
            setTimeout(() => {
                compatibled = true; // 已作兼容处理，上一步goto
                fun();

                resolve();
            }, 200);
        } else {
            fun();

            resolve();
        }
    });
}

export function nextpathnames(thisProps, nextprops) {
    if (!browser.versions.weChatMini) {
        return;
    }

    // 已作兼容处理， 并回到兼容处理页面
    if (compatibled && nextprops.location!.pathname === thispathname && thisProps.location!.pathname !== thispathname) {
        thispathname = null;
        compatibled = false;
    }
    nextpathname = nextprops.location!.pathname;
}

export function popstateFun() {
    if (nextpathname && !thispathname) {
        wx['miniProgram'].navigateBack();
    }
}

export function addEventListeners() {
    browser.versions.weChatMini && browser.versions.android && !client['noCompatibleWxmini'] && window.addEventListener('popstate', popstateFun);
}

/*——————————————————————————————适配小程序安卓返回end——————————————————————————————*/
