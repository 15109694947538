import { setStorageObject, getStorageObject, removeStorage } from "../funcs/storage";
import {debounce, throttle} from 'lodash'

/**
 * Park类型
 */
export interface Park {
    id: string;
    parkName: string;
    parkCode?: string;
}
/**
 * 存取park，必须调用该方法
 */
export function setCurrentPark(park:Park) {
    setStorageObject("park", park);
}
/**
 * 存取park，必须调用该方法
 */
export function getCurrentPark():Park {
    return getStorageObject("park")!;
}
/**
 * 存取parkId，必须调用该方法
 */
export function getCurrentParkId(): string {
    return getCurrentPark()?.id;
}
/**
 * 存取parkName，必须调用该方法
 */
export function getCurrentParkName(): string {
    return getCurrentPark()?.parkName;
}
/**
 * 删除park，必须调用该方法
 */
export function removeCurrentPark() {
    removeStorage("park")!;
}
/**
 * Unit类型
 */
export interface Unit {
    id: string;
    name: string;
}
/**
 * 存取Unit，必须调用该方法
 */
export function setCurrentUnit(unit:Unit) {
    setStorageObject("unit", unit);
}
/**
 * 存取Unit，必须调用该方法
 */
export function getCurrentUnit():Unit {
    return getStorageObject("unit")!;
}
/**
 * 存取UnitID，必须调用该方法
 */
export function getCurrentUnitId(): string {
    return getCurrentUnit()?.id;
}
/**
 * 存取UnitName，必须调用该方法
 */
export function getCurrentUnitName(): string {
    return getCurrentUnit()?.name;
}
/**
 * 删除Unit，必须调用该方法
 */
export function removeCurrentUnit() {
    removeStorage("unit")!;
}

export {
    debounce,
    throttle
}
