import { HttpService, resolveService, getCurrentParkId } from "@reco-m/core";
export class StateFlowHttpService extends HttpService {
    constructor() {
        super("stateflow/project");
    }

    createState(data: any) {
        return this.httpPost("create-state", Object.assign({ parkId: getCurrentParkId() }, data));
    }

    getState(data: any) {
        return this.httpGet("state", this.resolveRequestParams(Object.assign({ viewAsCreator: true }, data)));
    }

    getStateVariables(data: any) {
        return this.httpGet("state-variables", this.resolveRequestParams(data));
    }

    transitState(data) {
        return this.httpPost("transit-state", Object.assign({ viewAsCreator: true }, data));
    }

    getStateRoutes(data) {
        return this.httpPost("state-routes", Object.assign({ viewAsCreator: true }, data));
    }
}
export const stateFlowService = resolveService(StateFlowHttpService);
