import { HttpService, resolveService } from "@reco-m/core";

/**
 * 企业招聘信息管理
 */
export class RecruitHttpService extends HttpService {
    constructor() {
        super("businessrecruitment/position");
    }

    /**
     * 取消发布职位
     * @param id
     * @returns
     */
    withdraw(id) {
        return this.httpPut("withdraw/" + id);
    }
    /**
     * 上下架功能
     * @param data
     * @returns
     */
    effect(data) {
        return this.httpPut("effect/", data);
    }

    /**
     * 企业招聘-首页招聘信息
     * @param data
     * @returns
     */
    getRecruitPage(data) {
        return this.httpGet("home-page-recruitment", this.resolveRequestParams(data));
    }

    /**
     * 企业招聘-首页推荐
     * @param data
     * @returns
     */
    getHomePageRecommend(data) {
        return this.httpGet("recommended", this.resolveRequestParams(data));
    }
    /**
     * 推荐职位
     * @param data
     * @returns
     */
    getRecommend(data) {
        return this.httpGet("recommended-position", this.resolveRequestParams(data));
    }
    /**
     * 增加浏览次数
     * @param id
     */
    browse(id) {
        return this.httpPut("browse/" + id);
    }

    /**
     * 企业招聘收藏-分页查询
     * @param data
     * @returns
     */
    getCollectionPaged(data) {
        return this.httpGet("collection-detail-paged", this.resolveRequestParams(data));
    }
}
/**
 * 企业招聘信息
 */
export const recruitService = resolveService(RecruitHttpService);
