import React from "react";

import { PureComponent } from "@reco-m/core";
import { Empty } from "antd";

export namespace NoData {
    export interface IProps extends PureComponent.IProps {
        icon?: any;
        text?: any;
        className?: string;
        complete?: any;
    }

    export interface IState extends PureComponent.IState {}

    export class Component<P extends NoData.IProps = NoData.IProps, S extends NoData.IState = NoData.IState> extends PureComponent.Base<P, S> {
        static displayName = "NoData";
        static defaultProps: any = {
            text: "暂无数据",
            className: "",
        };

        render(): React.ReactNode {
            return <Empty className={this.classnames('bi-empty', this.props.className)} image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ height: 60 }} description={this.props.text}></Empty>;
        }
    }
}
