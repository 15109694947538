import React from "react";

import dayjs from "dayjs";
import { isString } from "lodash";
import { TimePicker, TimePickerProps } from "antd";

import { BiPickerComponent } from "./bi.picker";

export namespace BiTimePicker {
    export interface IProps extends BiPickerComponent.IProps {
        ref: any;
    }

    export interface IState extends BiPickerComponent.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BiPickerComponent.Base<P & TimePickerProps, S> {
        static defaultProps: any = {
            format: "HH:mm:ss",
        };

        onChange(value: dayjs.Dayjs | null) {
            const { format, onChange } = this.props;

            if (onChange) {
                onChange(value?.format(format));
            }
        }

        render(): React.ReactNode {
            const { format, value } = this.props;

            return (
                <TimePicker
                    disabledTime={this.disabledDateTime.bind(this)}
                    {...this.props}
                    value={value && isString(value) && dayjs(value, format)}
                    onChange={(e) => this.onChange(e)}
                />
            );
        }
    }
}
