import { EffectsMapObject } from "dva";
import { getCurrentParkId } from "@reco-m/core";
import { getSearchUrl } from "@reco-w/core-ui";

import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";

export const SearchBaseEffects: EffectsMapObject = {
    /**
     * 获取搜索列表
     * @param { message, callback, props, pageIndex：作为判断是否需要重新搜索, pageSize：作为判断是否需要重新搜索, searchType：搜索类型, defaultPageSize = 6, extraData：额外参数, listMap = "getList"：列表方法 }
     * @param { call, put }
     */
    *getSearchList({ message, callback, props, pageIndex, pageSize, searchType, defaultPageSize = 6, extraData, listMap = "getList" }, { call, put }) {
        try {
            yield put({ type: "showLoading" });

            const params = getSearchUrl(
                [CommonSearchParamInUrlEnum.globalKey, CommonSearchParamInUrlEnum.pageIndex, CommonSearchParamInUrlEnum.pageSize, CommonSearchParamInUrlEnum.searchType],
                props
            );

            if (
                params[CommonSearchParamInUrlEnum.searchType] &&
                Number(params[CommonSearchParamInUrlEnum.searchType]) === searchType &&
                (pageIndex !== params[CommonSearchParamInUrlEnum.pageIndex] || pageSize !== params[CommonSearchParamInUrlEnum.pageSize])
            ) {
                callback && callback(params[CommonSearchParamInUrlEnum.pageIndex], params[CommonSearchParamInUrlEnum.pageSize]);

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || defaultPageSize,
                        globalKey: params[CommonSearchParamInUrlEnum.globalKey],
                    },
                });

                const data = {
                    key: params[CommonSearchParamInUrlEnum.globalKey],
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || defaultPageSize,
                    ...extraData,
                };

                yield put({ type: listMap, data: data, message, searchData: data });
            }
        } catch (e) {
            yield call(message?.error, "" + e.errmsg);
            yield put({ type: "hideLoading" });
        }
    },
};
