import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getCurrentParkId, formatDate } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { CommonSearchParamInUrlEnum } from "@reco-w/common-common";
import { policyDeclareService } from "@reco-w/policy-service";
import { Namespaces, SearchParamInUrlEnum } from "./common";

export namespace newMyPolicyDeclareSimpleModel {
    export const namespace = Namespaces.newMyPolicyDeclareSimple;

    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState", message });
                yield yield put({ type: "getCertify", message });
                yield put({ type: "getDataByUrl", message, props });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取会员认证信息
         * @param { message }
         * @param { call, put, select }
         */
        *getCertify({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message, isRefreshUser: true });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    certifyDetail = memberCommonState.member;

                yield put({ type: "input", data: { certifyDetail, isCertifyAllow: certifyDetail?.status === CertifyStatusEnum.allow } });
            } catch (e) {
                yield put({ type: "hideLoading" });
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取申报列表
         */
        *getDataByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(
                    [
                        CommonSearchParamInUrlEnum.key,
                        CommonSearchParamInUrlEnum.pageIndex,
                        CommonSearchParamInUrlEnum.pageSize,
                        SearchParamInUrlEnum.year,
                        SearchParamInUrlEnum.declareStatus,
                    ],
                    props
                );

                yield put({
                    type: "input",
                    data: {
                        key: params[CommonSearchParamInUrlEnum.key],
                        currentPage: Number(params[CommonSearchParamInUrlEnum.pageIndex]) || 1,
                        pageSize: Number(params[CommonSearchParamInUrlEnum.pageSize]) || 6,
                        year: params[SearchParamInUrlEnum.year],
                        status: params[SearchParamInUrlEnum.declareStatus],
                    },
                });

                const year = +(params[SearchParamInUrlEnum.year] || 0);

                const data = {
                    detailTitle: params[CommonSearchParamInUrlEnum.key],
                    parkId: getCurrentParkId(),
                    pageIndex: params[CommonSearchParamInUrlEnum.pageIndex] || 1,
                    pageSize: params[CommonSearchParamInUrlEnum.pageSize] || 6,
                    inputTimeBegin: year ? formatDate(new Date(year, 0, 1)) : undefined,
                    inputTimeEnd: year ? formatDate(new Date(year + 1, 0, 1).dateAdd("d", -1)) + " 23:59:59" : undefined,
                    status: params[SearchParamInUrlEnum.declareStatus],
                };

                yield put({ type: "getData", data, message });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        /**
         * 获取申报列表
         */
        *getData({ message, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const state: any = yield select((state) => state[Namespaces.newMyPolicyDeclareSimple]),
                    isCertifyAllow = state.isCertifyAllow,
                    certifyDetail = state.certifyDetail;

                let result: any;

                if (isCertifyAllow) {
                    result = yield call(policyDeclareService.getPaged, { ...data, customerId: certifyDetail?.companyId });
                }

                yield put({ type: "input", data: result });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(newMyPolicyDeclareSimpleModel);
