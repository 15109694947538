import React from "react";

import {UploadProps} from "antd/lib/upload";

import {BaseAttachDetail} from "@reco-m/core";

import {getDefaultProps} from "./util";

import {UploadWrap} from "./upload.wrap";

export type AttachDetailProps = Omit<BaseAttachDetail.IProps, "children"> & UploadProps;

export namespace AttachDetail {
    export interface IProps extends AttachDetailProps {
    }

    export interface IState extends BaseAttachDetail.IState {
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BaseAttachDetail.Component<P, S> {
        static displayName = "AttachDetail";
        static defaultProps = getDefaultProps();


        render(): React.ReactNode {
            let tprops = {
                readonly: true
            } as any;
            return <UploadWrap.Component {...this.props} {...tprops} />;
        }
    }
}
