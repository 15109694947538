import { ArticleCatalogueEnum } from "@reco-w/article-models";
import { mtaH5Click, statisticsEvent } from "@reco-w/core-ui";
/**
 * 不同资讯类别埋点
 * @param code
 * @param map
 */
export function statisticArticle(code, map) {
    mtaH5Click(getStatisticArticle(code, map));
}

/**
 * 不同资讯类别埋点
 * @param code
 * @param map
 */
export function getStatisticArticle(code, map) {
    let str = "";
    switch (code) {
        case ArticleCatalogueEnum.article:
            str = "Article";
            break;
        case ArticleCatalogueEnum.notification:
            str = "Notification";
            break;
        default:
            break;
    }
    let eventMap = map + str;
    return statisticsEvent[eventMap];
}
