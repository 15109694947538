import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, getCurrentParkId, pictureService } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { SourceTextEnum, IParkBindTableNameEnum } from "@reco-w/common-common";
import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { commentService } from "@reco-w/comment-service";

import { TopicSourceTypeEnum, Namespaces } from "./common";
export namespace commentInputModel {
    export const namespace = Namespaces.commentInput;

    export const state: any = freeze(
        {
            commentContent: "",
            submitLoading: false,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        initPage({}, {}) {},
        /**
         * 获取头像
         */
        *getAvatar({ message }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                let memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user;
                const currentUser = user.currentUser;

                let pictureSrc = yield call(pictureService.getPictureList, {
                    bindTableName: IParkBindTableNameEnum.account,
                    bindTableId: currentUser.id,
                    customType: 1,
                });
                yield put({ type: "input", data: { avatar: pictureSrc ? pictureSrc[pictureSrc.length - 1] : "", currentUser: currentUser } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 提交评论
         */
        *submitCommentContent({ message, commentSuccess, data }, { call, put }) {
            try {
                const postReturnData = yield call(commentService.post, {
                    replyId: 0,
                    source: SourceTextEnum.web,
                    sourceValue: TopicSourceTypeEnum.web,
                    parkId: getCurrentParkId(),
                    ...data,
                });
                if (postReturnData) {
                    yield call(commentSuccess, "评论成功");
                }
            } catch (e) {
                yield put({ type: "input", data: { submitLoading: false } });
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(commentInputModel);
