import { HttpService, resolveService } from "@reco-m/core";

/**
 * 企业主页信息管理
 */
export class EnterpriseHttpService extends HttpService {
    constructor() {
        super("businesshomepage/enterprise");
    }

    /**
     * 通过所属企业id获取企业主页信息
     * @param id
     * @returns
     */
    getDetail(id) {
        return this.httpGet("customer/" + id);
    }
    /**
     * 猜你喜欢
     * @param data
     * @returns
     */
    getLikeList(data) {
        return this.httpGet("guess-like", this.resolveRequestParams(data));
    }

    /**
     * 取消发布企业
     * @param id
     * @returns
     */
    withdraw(id) {
        return this.httpPut("withdraw/" + id);
    }
    /**
     * 上下架功能
     * @param data
     * @returns
     */
    effect(data) {
        return this.httpPut("effect/", data);
    }

    /**
     * 园区企业—查询推荐企业
     * @param data
     * @returns
     */
    getRecommend(data) {
        return this.httpGet("recommend-company", this.resolveRequestParams(data));
    }
    /**
     * 增加浏览次数
     * @param id
     */
    browse(id) {
        return this.httpPut("browse/" + id);
    }
    /**
     * 查询工单列表
     * @param data
     * @returns
     */
    getOrderList(data) {
        return this.httpGet("workorder", this.resolveRequestParams(data));
    }

    /**
     * 企业主页收藏-分页查询
     * @param data
     * @returns
     */
    getCollectionPaged(data) {
        return this.httpGet("collection-detail-paged", this.resolveRequestParams(data));
    }
}
/**
 * 企业主页信息
 */
export const enterpriseService = resolveService(EnterpriseHttpService);
