import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, formatDate } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { marketService } from "@reco-w/market-service";

import { Namespaces } from "./common";

export namespace marketInDetailModel {
    export const namespace = Namespaces.marketInDetailModel;

    export const state: any = freeze(
        {
            isAgree: true,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ error, callback }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getMyInstitutionList", callback });
            } catch (e) {
                yield call(error, "" + e.errmsg);
            }
        },
        /**
         * 获取我的机构详情
         */
        *getMyInstitutionList({ error, callback }, { call, put }) {
            try {
                const marketDetail = yield call(marketService.getMyInstitution);
                const {
                    serviceInstitutionCategoryDetailVMList: insCategory = [],
                    serviceInstitutionBasicFormVM: insBasic = {},
                    contactPersonalCommonVM: personCommon = {},
                    serviceAllianceSubmitOrderVM: subOrder = {},
                } = marketDetail || {};
                let ServiceCatalogueNamesArr = [] as any;
                insCategory &&
                    insCategory.forEach((item) => {
                        let temp = {
                            id: item.id,
                            tagName: item.serviceCategory,
                            tagValue: item.serviceCategoryValue,
                        };
                        ServiceCatalogueNamesArr.push(temp);
                    });
                yield put({
                    type: "input",
                    data: {
                        marketDetail,
                        organizationName: insBasic.institutionName,
                        website: insBasic.website,
                        chosenCatalogue: ServiceCatalogueNamesArr,
                        chosenCatalogueText: (ServiceCatalogueNamesArr?.length > 0 && ServiceCatalogueNamesArr?.map((x) => x.tagName)?.join(",")) || "",
                        address: personCommon.address,
                        contactPerson: personCommon.fullName,
                        mobile: personCommon.mobile,
                        registDate: formatDate(subOrder.inputTime, "yyyy-MM-dd"),
                        businessCode: insBasic.creditCode,
                        email: personCommon.email,
                        tel: personCommon.tel,
                        summary: insBasic.detail,
                        caseinfo: insBasic.serviceCase,
                        bindTableId: personCommon.bindTableId,
                        returnReason: insBasic?.returnReason,
                        marketStatus: insBasic?.status,
                    },
                });

                callback && callback(personCommon);
            } catch (e) {
                yield call(error, "" + e.errmsg);
            }
        },
    };
}

app.model(marketInDetailModel);
