import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreState, CoreEffects, CoreReducers, getCurrentParkId, formatDateTime } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";

import { policyService, policyDeclareService } from "@reco-w/policy-service";
import { Namespaces, POLICY_TAG_ARR } from "./common";
import { getMultiSelectIDs } from "./utils";

export namespace policyDeclareModel {
    export const namespace = Namespaces.policyDeclare;

    export const state: any = freeze({
        ...CoreState,
    });

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, listdata, selectedTagObj, detailID, callback }, { put }) {
            if (detailID) {
                try {
                    yield put({ type: "getPolicyDetailAction", data: detailID, callback, message });
                    yield put({
                        type: "getPolicy",
                        data: listdata,
                        selectedTagObj,
                        message,
                    });
                } catch (e) {}
            }
        },
        /**
         * 获取用户信息
         * @param { message, data, callback }
         * @param { call, put,select }
         */
        *getUserInfo({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                const mymenuState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = mymenuState.member,
                    user = mymenuState.user;
                if (member && member.status === CertifyStatusEnum.allow) yield put({ type: "input", data: { submitSuccess: true } });

                yield put({
                    type: "input",
                    data: {
                        account: user && user.currentUser,
                        realname: (user && user.currentUser && user.currentUser.realName) || "",
                        inputerMobile: (user && user.currentUser && user.currentUser.mobile) || "",
                        currentUser: user && user.currentUser,
                        member,
                        customerName: member?.companyName,
                        customerId: member?.companyId,
                        creditCode: member?.creditCode,
                    },
                });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取政策详情
         * @param { message, data, callback }
         * @param { call, put }
         */
        *getPolicyDetailAction({ message, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const policyDetailData = yield call(policyService.get, data);

                yield put({ type: "input", data: { policyDetailData } });
                yield call(callback!, policyDetailData);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取申报详情
         * @param { message, data, callback }
         * @param { call, put }
         */
        *getApproval({ message, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const approval = yield call(policyDeclareService.get, data);
                approval.realname = approval.realName;
                yield put({ type: "input", data: approval });

                callback && callback(approval);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取申报详情
         * @param { message, data, callback }
         * @param { call, put }
         */
        *getDeclare({ message, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                const declare = yield call(policyDeclareService.get, data);
                declare.realname = declare.realName;
                yield put({ type: "input", data: declare });
                yield call(callback!, declare);
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取政策列表
         * @param { message, data, selectedTagObj }
         * @param { call, put, select }
         */
        *getPolicy({ message, data, selectedTagObj }, { call, put, select }) {
            try {
                let params;

                yield yield put({ type: `${Namespaces.policy}/getTagData`, message, tagt: { tagClass: POLICY_TAG_ARR.map((x) => x.tagCode).join(",") } });

                let selectedTagIDs: any[] = [];
                if (selectedTagObj) {
                    const { state } = yield select((state) => ({ state: state[Namespaces.policy] }));
                    Object.keys(selectedTagObj).forEach((key) => {
                        const values = selectedTagObj[key];
                        const ids = getMultiSelectIDs(values, state![key]);
                        selectedTagIDs = [...selectedTagIDs, ...ids];
                    });
                }

                params = {
                    parkId: getCurrentParkId(),
                    isValid: true,
                    pageIndex: 1,
                    pageSize: 999,
                    ...data,
                    applyTagID: selectedTagIDs,
                };
                const result = yield call(policyService.getPaged, params);

                yield put({ type: "input", data: { policyList: result.items } });
            } catch (e) {
                yield call(message?.error, "" + e.errmsg);
            }
        },

        *submit({ data, callback, message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const result = data.id ? yield call(policyDeclareService.put, data.id, data) : yield call(policyDeclareService.post, data);

                result && callback(data.id ? data.id : result);
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取列表
         */
        *getApprovalAction({ error, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user && memberCommonState.user.currentUser;

                const result = yield call(policyDeclareService.getPaged, {
                    inputerId: user && user.id,
                    ...data,
                });

                yield put({
                    type: "input",
                    data: {
                        orderData: result.items.map((item, index) => {
                            return {
                                key: index + 1,
                                number: index + 1,
                                date: formatDateTime(item.inputTime),
                                ...item,
                            };
                        }),
                        dataSource: result,
                        total: Number(result.totalItems),
                    },
                });
            } catch (e) {
                yield call(error, "" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(policyDeclareModel);
